import React, { Component } from "react";
import Dropzone from "react-dropzone";
import {
  Button,
  Checkbox,
  Radio,
  DropdownButton,
  Col,
  Row,
  ControlLabel,
  FormControl,
  FormGroup,
  MenuItem,
  Nav,
  NavItem,
} from "react-bootstrap";
import DateTime from "react-datetime";
import moment from "moment";
import streamSaver from "./StreamSaver";
import { Redirect } from "react-router";
import imageCompression from "browser-image-compression";

import "./App.css";
import "./react-datetime.css";
import ItemSearch from "./ItemSearch";
import StreamerNav from "./StreamerNav";
import firebase from "./firebase";
import model from "./Model";
import Utilities from "./Utilities";

import closeIcon from "./img/icon/icon_close.svg";
import moveBackIcon from "./img/icon/icon_back.svg";
import moveNextIcon from "./img/icon/icon_next.svg";
import deleteIcon from "./img/icon/icon_delete.svg";

const db = firebase.firestore();

const MAX_LENGTH_OF_LIVE_NAME = 256;
const MAX_LENGTH_OF_STREAMER_COMMENT = 64;

export default class Previews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      liveId: "",
      name: "",
      image: null,
      compressedImage: null,
      archiveImage: null,
      compressedArchiveImage: null,
      banner: null,
      spBanner: null,
      datetime: null,
      shopIndex: 0,
      shops: [],
      liveTelops: [],
      liveItems: [],
      linkName: "",
      linkNameError: "",
      linkUrl: "",
      description: "",
      previewStreamerName: "",
      previewStreamerText: "",
      archiveComment: true,
      archiveImageUrl: "",
      buyButton: false,
      processing: false,
      showTelop: true,
      showFullTelop: false,
      ctaButton: true,
      unlist: false,
      viewsFlag: false,
      likeCountFlag: false,
      privateMode: false,
      surveyPermit: false,
      surveyMode: false,
      dmMode: false,
      xPostMode: false,
      xPostText: "",
      xPostHashTag: "",
      onlyLivePermit: false,
      countDownFlag: true,
      enabled: true,
      isTest: false,
      isLoading: Boolean(this.props.edit),
      success: false,
      errors: {
        nameIsEmpty: false,
        nameIsTooLong: false,
        imageIsEmpty: false,
        datetimeIsEmpty: false,
      },
      warnings: {
        linkNameIsTooLong: false,
      },
      sessions: [],
      videoFile: null,
      videoErrors: [],
      videoMessage: "",
      videoProgress: "",
      videoUploading: false,
    };
  }

  componentDidMount = async () => {
    const uid = firebase.auth().currentUser.uid;
    const shopData = await db
      .collection("shops")
      .where("uid", "==", uid)
      .get();
    const shopId = shopData.docs[0].data().id;
    const surveyPermit = shopData.docs[0].data().surveyPermit || "OFF";
    const onlyLivePermit = shopData.docs[0].data().onlyLivePermit || "OFF";
    this.setState({ surveyPermit: surveyPermit === "ON" });
    this.setState({ onlyLivePermit: onlyLivePermit === "ON" });
    db.collection("streamers")
      .doc(uid)
      .collection("shops")
      .get()
      .then(model.listData)
      .then((shops) => {
        if (!this.props.permission.seller) {
          shops = shops.filter((shop) => shop.id !== shopId);
        }
        this.setState({ shops });
        const shopPromises = [];
        shops.forEach((shop) =>
          shopPromises.push(
            db
              .collection("shops")
              .doc(shop.id)
              .get()
              .then((shopDoc) => shopDoc.data())
          )
        );
        Promise.all(shopPromises).then((shops) => this.setState({ shops }));

        if (this.props.edit) {
          const liveId = this.props.match.params.id;
          let streamer = "";
          db.collection("streamers")
            .doc(uid)
            .get()
            .then((streamerSnapshot) => {
              if (streamerSnapshot.exists) {
                streamer = streamerSnapshot.data();
              }
            });
          db.collection("lives")
            .doc(liveId)
            .get()
            .then((doc) => {
              const live = doc.data();
              if (live.uid !== uid) {
                this.setState({ redirect: true });
                return;
              }
              let shopIndex = 0;
              shops.forEach((shop, i) => {
                if (shop.id === live.shopId) {
                  shopIndex = i;
                }
              });
              this.setState({
                liveId,
                shopIndex,
                name: live.name,
                image: live.image,
                archiveImage: live.archiveImage,
                banner: live.banner,
                spBanner: live.spBanner,
                datetime: moment(live.datetime.toDate()),
                liveTelops: live.telops || [],
                liveItems: live.items,
                linkName: live.linkName || "",
                linkUrl: live.linkUrl,
                description: live.description,
                previewStreamerName: live.previewStreamerName
                  ? live.previewStreamerName
                  : streamer.name,
                previewStreamerText: live.previewStreamerText,
                archiveComment: Boolean(live.archiveComment),
                archiveImageUrl: live.archiveImageUrl,
                buyButton: Boolean(live.buyButton),
                showTelop: Boolean(live.showTelop),
                showFullTelop: Boolean(live.showFullTelop),
                ctaButton: Boolean(live.ctaButton),
                unlist: Boolean(live.unlist),
                viewsFlag: Boolean(live.viewsFlag),
                likeCountFlag: Boolean(live.likeCountFlag),
                privateMode: Boolean(live.privateMode),
                surveyMode: Boolean(live.surveyMode),
                dmMode: Boolean(live.dmMode),
                xPostMode: Boolean(live.xPostMode),
                xPostText: live.xPostText || "",
                xPostHashTag: live.xPostHashTag ? live.xPostHashTag.split(' ').map(word => word.replace('#', '').trim()).filter(word => word !== '').join(',') : "",
                countDownFlag: Boolean(live.countDownFlag),
                enabled: Boolean(live.enabled),
                isTest: Boolean(live.isTest),
                isLoading: false,
                errors: {
                  nameIsEmpty: !live.name,
                  nameIsTooLong:
                    Utilities.countNumberOfCharactersAsHankaku(live.name) >
                    MAX_LENGTH_OF_LIVE_NAME,
                  imageIsEmpty: !live.image,
                  datetimeIsEmpty: !live.datetime,
                },
              });
              db.collection("sessions")
                .where("liveId", "==", live.id)
                .orderBy("created", "desc")
                .get()
                .then((data) => {
                  const sessions = data.docs
                    .map((doc) => doc.data())
                    .filter(
                      (session) =>
                        !session.deleted && !session.live && session.url
                    );
                  this.setState({ sessions });
                });
            });
        }
      });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onTelopOptionChange = (e) => {
    this.setState({
      showTelop: e.target.value === "showTelop",
      showFullTelop: e.target.value === "showFullTelop",
      buyButton: e.target.value === "buyButton",
    });
    if (e.target.value === "buyButton") this.setState({ ctaButton: true });
  };

  onReleaseOptionChange = (e) => {
    if (e.target.value === "public") {
      this.setState({ unlist: false, privateMode: false });
    } else {
      this.setState({
        unlist: e.target.value === "unlist",
        privateMode: e.target.value === "privateMode",
      });
    }
  };

  onSurveyOptionChange = (e) => {
    const isSurveyModeOn = e.target.value === "ON";
    this.setState({ surveyMode: isSurveyModeOn });
  };

  onDmOptionChange = (e) => {
    const isDmMode = e.target.value === "ON";
    this.setState({ dmMode: isDmMode });
  };

  onXPostOptionChange = (e) => {
    const isXPostMode = e.target.value === "ON";
    this.setState({ xPostMode: isXPostMode });
  };

  onChangeName = (e) => {
    const value = e.target.value;
    this.setState({
      name: value,
      errors: {
        nameIsEmpty: !value,
        nameIsTooLong:
          Utilities.countNumberOfCharactersAsHankaku(value) >
          MAX_LENGTH_OF_LIVE_NAME,
      },
    });
  };

  /**
   * 画像圧縮処理
   * @param {File} file
   * @param {number} maxWidthOrHeight
   * @returns Promise<File | Error>
   */
  compressImage = async (file, maxWidthOrHeight) => {
    const controller = new AbortController();
    const options = {
      signal: controller.signal,
      initialQuality: 0.8,
      maxWidthOrHeight: maxWidthOrHeight,
    };
    try {
      const compressedImage = await imageCompression(file, options);
      return compressedImage;
    } catch (error) {
      console.error(error);
      controller.abort(new Error(""));
      return Error;
    }
  };

  onFileChange = async (files) => {
    const file = files[0];
    if (file) {
      if (!file.type.match(/^image\//)) {
        alert("画像ファイルをアップロードしてください。");
        return;
      }
      try {
        const image = await this.compressImage(file, 720);
        const compressedImage = await this.compressImage(file, 320);
        const name = `image${moment().format("YYYYMMDDHHmmss")}`;
        const compressedName = `image${moment().format(
          "YYYYMMDDHHmmss"
        )}compressed`;
        const ref = firebase.storage().ref();
        ref
          .child(`${firebase.auth().currentUser.uid}/${name}`)
          .put(image)
          .then((snapshot) =>
            snapshot.ref
              .getDownloadURL()
              .then((url) =>
                this.setState({ image: url, errors: { imageIsEmpty: false } })
              )
          );
        ref
          .child(`${firebase.auth().currentUser.uid}/${compressedName}`)
          .put(compressedImage)
          .then((snapshot) =>
            snapshot.ref
              .getDownloadURL()
              .then((url) => this.setState({ compressedImage: url }))
          );
      } catch (error) {
        alert("圧縮に失敗しました。時間をおいてやり直してください。");
        this.setState({ image: null, errors: { imageIsEmpty: true } });
      }
    } else {
      this.setState({ image: null, errors: { imageIsEmpty: true } });
    }
  };

  onArchiveWaitingImgChange = async (files) => {
    const file = files[0];
    if (file) {
      if (!file.type.match(/^image\//)) {
        alert("画像ファイルをアップロードしてください。");
        return;
      }
      try {
        const image = await this.compressImage(file, 720);
        const compressedImage = await this.compressImage(file, 320);
        const name = `archiveImage${moment().format("YYYYMMDDHHmmss")}`;
        const compressedName = `archiveImage${moment().format(
          "YYYYMMDDHHmmss"
        )}compressed`;
        const ref = firebase.storage().ref();
        ref
          .child(`${firebase.auth().currentUser.uid}/${name}`)
          .put(image)
          .then((snapshot) =>
            snapshot.ref
              .getDownloadURL()
              .then((url) => this.setState({ archiveImage: url }))
          );
        ref
          .child(`${firebase.auth().currentUser.uid}/${compressedName}`)
          .put(compressedImage)
          .then((snapshot) =>
            snapshot.ref
              .getDownloadURL()
              .then((url) => this.setState({ compressedArchiveImage: url }))
          );
      } catch (error) {
        alert("圧縮に失敗しました。時間をおいてやり直してください。");
        this.setState({ archiveImage: null });
      }
    } else {
      this.setState({ archiveImage: null });
    }
  };

  onBannerChange = (files) => {
    const file = files[0];
    if (file) {
      if (!file.type.match(/^image\//)) {
        alert("画像ファイルをアップロードしてください。");
        return;
      }
      const size = file.size / 1024;
      if (size > 200) {
        alert("200KB以下の画像を選択してください。");
        return;
      }
      const name = `banner${moment().format("YYYYMMDDHHmmss")}`;
      firebase
        .storage()
        .ref()
        .child(`${firebase.auth().currentUser.uid}/${name}`)
        .put(file)
        .then((snapshot) =>
          snapshot.ref
            .getDownloadURL()
            .then((url) => this.setState({ banner: url }))
        );
    } else {
      this.setState({ banner: null });
    }
  };

  onSpBannerChange = (files) => {
    const file = files[0];
    if (file) {
      if (!file.type.match(/^image\//)) {
        alert("画像ファイルをアップロードしてください。");
        return;
      }
      const size = file.size / 1024;
      if (size > 200) {
        alert("200KB以下の画像を選択してください。");
        return;
      }
      const name = `spBanner${moment().format("YYYYMMDDHHmmss")}`;
      firebase
        .storage()
        .ref()
        .child(`${firebase.auth().currentUser.uid}/${name}`)
        .put(file)
        .then((snapshot) =>
          snapshot.ref
            .getDownloadURL()
            .then((url) => this.setState({ spBanner: url }))
        );
    } else {
      this.setState({ spBanner: null });
    }
  };

  onTelopChange = (files) => {
    [...files].forEach((file, index) => {
      if (file) {
        if (!file.type.match(/^image\//)) {
          alert("画像ファイルをアップロードしてください。");
          return;
        }
        const size = file.size / 1024;
        if (size > 100) {
          alert("100KB以下の画像を選択してください。");
          return;
        }
        const id = `telop${moment().format("YYYYMMDDHHmmss")}-${index}`;
        firebase
          .storage()
          .ref()
          .child(`${firebase.auth().currentUser.uid}/${id}`)
          .put(file)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              if (this.state.liveTelops.length < 10) {
                this.addTelop({
                  id,
                  image: url,
                  url: ''
                });
              }
            });
          });
      }
    });
    files = null;
  };

  onChangeDatetime = (e) => {
    const value = e;
    this.setState({ datetime: value, errors: { datetimeIsEmpty: !value } });
  };

  onChangeLinkName = (e) => {
    const value = e.target.value;
    this.setState({
      linkName: value,
      warnings: {
        linkNameIsTooLong:
          Utilities.countNumberOfCharactersAsHankaku(value) > 14,
      },
    });
  };

  onSelectShop = (shopIndex) => this.setState({ shopIndex });

  removeBanner = () => this.setState({ banner: null });

  removeSpBanner = () => this.setState({ spBanner: null });

  addTelop = (telop) => {
    const liveTelops = this.state.liveTelops;
    if (liveTelops.findIndex((i) => i.id === telop.id) < 0) {
      liveTelops.push(telop);
      this.setState({ liveTelops });
    }
  };

  removeTelop = (telop) => {
    const liveTelops = this.state.liveTelops;
    const index = liveTelops.findIndex((i) => i.id === telop.id);
    if (index > -1) {
      liveTelops.splice(index, 1);
      this.setState({ liveTelops });
    }
  };

  moveBackTelop = (telop) => {
    const liveTelops = this.state.liveTelops;
    const index = liveTelops.findIndex((i) => i.id === telop.id);
    if (index > 0) {
      const element = liveTelops.splice(index, 1);
      liveTelops.splice(index - 1, 0, ...element);
      this.setState({ liveTelops });
    }
  };

  moveNextTelop = (telop) => {
    const liveTelops = this.state.liveTelops;
    const index = liveTelops.findIndex((i) => i.id === telop.id);
    if (index < liveTelops.length - 1) {
      const element = liveTelops.splice(index, 1);
      liveTelops.splice(index + 1, 0, ...element);
      this.setState({ liveTelops });
    }
  };

  addItem = (item) => {
    const liveItems = this.state.liveItems;
    if (!liveItems.map((a) => a.id).includes(item.id)) {
      liveItems.push({ id: item.id, name: item.name, image: item.image });
      this.setState({ liveItems });
    }
  };

  removeItem = (item) => {
    const liveItems = this.state.liveItems;
    const index = liveItems.map((a) => a.id).indexOf(item.id);
    if (index > -1) {
      liveItems.splice(index, 1);
      this.setState({ liveItems });
    }
  };

  moveBackItem = (item) => {
    const liveItems = this.state.liveItems;
    const index = liveItems.map((a) => a.id).indexOf(item.id);
    if (index > 0) {
      const element = liveItems.splice(index, 1);
      liveItems.splice(index - 1, 0, ...element);
      this.setState({ liveItems });
    }
  };

  moveNextItem = (item) => {
    const liveItems = this.state.liveItems;
    const index = liveItems.map((a) => a.id).indexOf(item.id);
    if (index < liveItems.length - 1) {
      const element = liveItems.splice(index, 1);
      liveItems.splice(index + 1, 0, ...element);
      this.setState({ liveItems });
    }
  };

  setLive = async () => {
    const {
      name,
      image,
      compressedImage,
      archiveImage,
      compressedArchiveImage,
      banner,
      spBanner,
      datetime,
      linkName,
      linkUrl,
      description,
      previewStreamerName,
      previewStreamerText,
      archiveComment,
      archiveImageUrl,
      buyButton,
      showTelop,
      showFullTelop,
      ctaButton,
      unlist,
      likeCountFlag,
      viewsFlag,
      privateMode,
      surveyMode,
      dmMode,
      xPostMode,
      xPostText,
      xPostHashTag,
      countDownFlag,
      enabled,
      isTest,
      liveTelops,
      liveItems,
      errors,
    } = this.state;
    if (
      errors.nameIsEmpty ||
      errors.nameIsTooLong ||
      errors.imageIsEmpty ||
      errors.datetimeIsEmpty
    ) {
      return;
    }

    const shop = this.state.shops[this.state.shopIndex];
    const sessionEnabled = Boolean(shop && shop.enabled) && enabled;
    const bannerValue = banner ? banner : "";
    if (name && image && datetime) {
      if (this.state.processing) {
        return;
      }
      this.setState({ processing: true });
      const liveData = {
        name,
        image,
        compressedImage,
        archiveImage: archiveImage ? archiveImage : image,
        compressedArchiveImage: compressedArchiveImage
          ? compressedArchiveImage
          : compressedImage,
        banner: bannerValue,
        spBanner: spBanner ? spBanner : bannerValue,
        linkName,
        linkUrl: linkUrl
          ? linkUrl.startsWith("http")
            ? linkUrl
            : `http://${linkUrl}`
          : "",
        description: description ? description : "",
        previewStreamerName: previewStreamerName ? previewStreamerName : "",
        previewStreamerText: previewStreamerText ? previewStreamerText : "",
        archiveImageUrl: archiveImageUrl ? archiveImageUrl : "",
        datetime: datetime.toDate(),
        uid: firebase.auth().currentUser.uid,
        shopId: shop.id,
        archiveComment,
        buyButton,
        showTelop,
        showFullTelop,
        ctaButton,
        unlist,
        likeCountFlag,
        viewsFlag,
        privateMode,
        surveyMode,
        dmMode,
        xPostMode,
        xPostText,
        xPostHashTag: xPostHashTag ? xPostHashTag.split(',').map(word => `#${word.trim()}`).join(' ') : '',
        countDownFlag,
        enabled,
        isTest,
        telops: liveTelops,
        items: liveItems,
      };
      if (this.props.edit) {
        await db
          .collection("lives")
          .doc(this.state.liveId)
          .update(liveData);
      } else {
        const liveDoc = db.collection("lives").doc();
        await liveDoc.set({ id: liveDoc.id, ...liveData, deletedAt: null });
      }
      this.setState({
        success: true,
        processing: false,
        errors: {
          nameIsEmpty: false,
          nameIsTooLong: false,
          imageIsEmpty: false,
          datetimeIsEmpty: false,
        },
      });
      if (this.props.edit) {
        db.collection("sessions")
          .where("liveId", "==", this.state.liveId)
          .orderBy("created", "desc")
          .get()
          .then((data) => {
            if (!data.empty) {
              //過去、未ライブのセッションの商品も変更。過去のセッションが再び表示されることは無いので
              data.docs.map((doc) =>
                db
                  .collection("sessions")
                  .doc(doc.id)
                  .update({ enabled: sessionEnabled, items: liveItems })
              );
            }
          });
      } else {
        this.setState({ added: true });
      }
    } else {
      this.setState({
        success: false,
        errors: {
          nameIsEmpty: !name,
          nameIsTooLong:
            Utilities.countNumberOfCharactersAsHankaku(name) >
            MAX_LENGTH_OF_LIVE_NAME,
          imageIsEmpty: !image,
          datetimeIsEmpty: !datetime,
        },
      });
    }
  };

  deleteLive = () => {
    if (window.confirm("このライブを削除します。よろしいですか？")) {
      db.collection("lives")
        .doc(this.state.liveId)
        .update({
          deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          document.location.pathname = "/streamer/previews/";
        });
    }
  };

  getDownloadArchiveUrl = (session) => {
    return session.optimized
      ? session.optimizedArchiveUrl || session.url
      : session.originalCreated
      ? session.url
      : null;
  };

  download = (session) => {
    const url = this.getDownloadArchiveUrl(session);
    if (!url) {
      return;
    }
    fetch(url, { mode: "cors" }).then((response) => {
      const readableStream = response.body;
      const fileStream = streamSaver.createWriteStream("archive.mp4");
      if (readableStream.pipeTo) {
        return readableStream.pipeTo(fileStream).then();
      }
      window.writer = fileStream.getWriter();
      const reader = readableStream.getReader();
      const pump = () =>
        reader.read().then((promise) => {
          if (promise.done) {
            window.writer.close();
          } else {
            window.writer.write(promise.value).then(pump);
          }
        });
      pump();
    });
  };

  onDeleteVideo = (session) => {
    const { sessions } = this.state;
    const target = sessions.find((v) => v.archiveId === session.archiveId);
    if (!target) {
      return;
    }
    const result = window.confirm(
      "一度削除した動画は元に戻せません。本当に削除しますか？"
    );
    if (!result) {
      return;
    }
    db.collection("sessions")
      .doc(session.sessionId)
      .set(
        {
          deleted: true,
        },
        {
          merge: true,
        }
      )
      .then(() => {
        target.deleted = true;
        this.setState({ sessions });
      });
  };

  onVideoFileChange = (files) => {
    const file = files[0];
    if (file) {
      if (!file.type.match(/\/mp4$/)) {
        alert("mp4ファイルをアップロードしてください。");
        return;
      }
      const size = file.size / 1024 / 1024 / 1024;
      if (size > 1.5) {
        alert("1.5GB以下の動画を選択してください。");
        return;
      }
      this.setState({ videoFile: file });
    }
  };

  onSubmitVideoFile = async () => {
    if (this.state.videoUploading) {
      return;
    }
    const lastSessionQuery = await db
      .collection("sessions")
      .where("liveId", "==", this.state.liveId)
      .orderBy("created", "desc")
      .limit(1)
      .get();
    const lastSession = lastSessionQuery.empty
      ? {}
      : lastSessionQuery.docs[0].data();
    if (
      lastSession.live ||
      (lastSession.stabilized && !lastSession.optimized)
    ) {
      alert("ライブ配信中のため動画をアップロードできません。");
      return;
    }
    if (this.state.videoFile) {
      this.setState({
        videoErrors: [],
        videoMessage:
          "動画をアップロード中です。ページを閉じないでください。動画の画質や長さによって数分から数十分かかります。",
        videoProgress: "",
        videoUploading: true,
      });
      const name = `video${moment().format("YYYYMMDDhhmmssSSS")}.mp4`;
      const uid = firebase.auth().currentUser.uid;
      const storageRef = firebase
        .app()
        .storage(model.PRIVATE_BUCKET)
        .ref();
      const uploadTask = storageRef
        .child(`${uid}/${name}`)
        .put(this.state.videoFile);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({
            videoProgress: `アップロード状況: ${Math.floor(progress)}%`,
          });
        },
        (error) => {
          this.setState({
            videoErrors: [...this.state.videoErrors, error.message],
            videoUploading: false,
          });
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            const sourceQuery = await db
              .collection("sessions")
              .where("liveId", "==", this.state.liveId)
              .where("stabilized", "!=", null)
              .orderBy("stabilized", "desc")
              .orderBy("created", "desc")
              .limit(1)
              .get();
            const source = sourceQuery.empty ? {} : sourceQuery.docs[0].data();
            const uploadWithoutSession = sourceQuery.empty;
            const session = {
              created: firebase.firestore.FieldValue.serverTimestamp(),
              originalCreated: uploadWithoutSession
                ? firebase.firestore.FieldValue.serverTimestamp()
                : source.originalCreated || source.created,
              availableAt: uploadWithoutSession ? 0 : source.availableAt || 0,
              stabilizationTime: uploadWithoutSession
                ? 0
                : source.stabilizationTime || 0,
              stabilized: uploadWithoutSession
                ? firebase.firestore.FieldValue.serverTimestamp()
                : source.stabilized || source.originalCreated || source.created,
              optimized: false,
              deleted: false,
              enabled: false,
              items: this.state.liveItems,
              live: false,
              liveId: this.state.liveId,
              shopId:
                this.state.shopIndex < this.state.shops.length
                  ? this.state.shops[this.state.shopIndex].id
                  : null,
              domain:
                this.state.shopIndex < this.state.shops.length
                  ? this.state.shops[this.state.shopIndex].domain
                  : null,
              isTest: this.state.isTest,
              resolution: "1920x1080",
              rotated: false,
              uid,
              url: downloadURL,
              optimizedArchiveHlsUrl: ''
            };
            db.collection("sessions")
              .add(session)
              .then((sessionRef) => {
                const sessionId = sessionRef.id;
                session.archiveId = sessionId;
                session.sessionId = sessionId;
                if (uploadWithoutSession) {
                  // Required for undistributed live to be displayed in reports
                  session.broadcastId = sessionId;
                }
                sessionRef.update(session).then(() => {
                  sessionRef.get().then((sessionSnapshot) => {
                    const newSession = sessionSnapshot.data();
                    this.setState({
                      videoMessage: "動画のアップロードが完了しました",
                      videoProgress: "",
                      videoUploading: false,
                      sessions: [newSession, ...this.state.sessions],
                    });
                  });
                });

                model.fetch(`api/session/${sessionId}/archive/`, "POST", {
                  url: downloadURL,
                });
              });
          });
        }
      );
    }
  };
  render() {
    const state = this.state;
    const streamerComment = `${this.state.previewStreamerName}：${this.state.previewStreamerText}`;
    const streamerCommentLength = Utilities.countNumberOfCharactersAsHankaku(
      streamerComment
    );
    if (state.redirect || state.added) {
      return (
        <Redirect
          to={{ pathname: "/streamer/previews", state: { added: state.added } }}
        />
      );
    }
    if (state.isLoading) {
      return <div></div>;
    }

    return (
      <div>
        <StreamerNav permission={this.props.permission} />
        <div className="content preview">
          <h2>ライブ予約</h2>
          <Nav bsStyle="pills" className="detail-nav nav-m-bk">
            <NavItem href="/streamer/previews" active={this.props.edit}>
              ライブ予約一覧
            </NavItem>
            {this.props.edit && (
              <NavItem className="detailNav_navItemChild">編集</NavItem>
            )}
            <NavItem href="/streamer/previews/new" active={!this.props.edit}>
              新しいライブ予約
            </NavItem>
            <NavItem href="/streamer/previews/trash">削除済みライブ</NavItem>
          </Nav>
          <div className="card">
            <form className="form form-horizontal">
              <FormGroup controlId="name">
                <Col componentClass={ControlLabel} sm={4}>
                  タイトル <div className="tagmust">必須</div>
                </Col>
                <Col sm={8}>
                  <div className="preview_titleContainer">
                    <FormControl
                      name="name"
                      componentClass="textarea"
                      rows="3"
                      placeholder="新着紹介"
                      value={state.name}
                      onChange={this.onChangeName}
                    />
                    {state.isTest && (
                      <div>
                        <span className="tagStyle testTag">テスト</span>
                      </div>
                    )}
                  </div>
                  {state.errors.nameIsEmpty && (
                    <p className="error">タイトルを入力してください</p>
                  )}
                  {state.errors.nameIsTooLong && (
                    <p className="error">
                      タイトルは半角{MAX_LENGTH_OF_LIVE_NAME}文字（全角
                      {MAX_LENGTH_OF_LIVE_NAME / 2}文字）以内で入力してください
                    </p>
                  )}
                </Col>
              </FormGroup>
              {!state.isTest && (
                <div>
                  <FormGroup controlId="image">
                    <Col componentClass={ControlLabel} sm={4}>
                      配信前ウェイティング画像（405x720）{" "}
                      <div className="tagmust">必須</div>
                    </Col>
                    <Col sm={8} className="hiddenOverflow">
                      <Dropzone
                        onDrop={this.onFileChange}
                        accept={{ "image/*": [] }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzoneContainer">
                            <div className="dropzone" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>ファイルアップロード</p>
                              <input
                                type="button"
                                value="ファイルを選択"
                                onChange={this.onFileChange}
                              ></input>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {state.image && (
                        <img
                          src={state.image}
                          alt="poster"
                          style={{
                            width: 225,
                            height: 400,
                            objectFit: "cover",
                          }}
                        />
                      )}
                      {state.errors.imageIsEmpty && (
                        <p className="error">
                          配信前ウェイティング画像を選択してください
                        </p>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="image">
                    <Col
                      componentClass={ControlLabel}
                      className="displayBlock"
                      sm={4}
                    >
                      アーカイブ用ウェイティング画像（405x720）
                    </Col>
                    <Col sm={8} className="hiddenOverflow">
                      <Dropzone
                        onDrop={this.onArchiveWaitingImgChange}
                        accept={{ "image/*": [] }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzoneContainer">
                            <div className="dropzone" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>ファイルアップロード</p>
                              <input
                                type="button"
                                value="ファイルを選択"
                                onChange={this.onArchiveWaitingImgChange}
                              ></input>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {state.archiveImage && (
                        <img
                          src={state.archiveImage}
                          alt="poster"
                          style={{
                            width: 225,
                            height: 400,
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Col>
                  </FormGroup>
                </div>
              )}
              <FormGroup controlId="datetime">
                <Col componentClass={ControlLabel} sm={4}>
                  配信時間 <div className="tagmust">必須</div>
                </Col>
                <Col sm={8}>
                  <DateTime
                    input={false}
                    onChange={this.onChangeDatetime}
                    value={state.datetime}
                  />
                  {state.errors.datetimeIsEmpty && (
                    <p className="error">配信時間を入力してください</p>
                  )}
                </Col>
              </FormGroup>
              <FormGroup controlId="description">
                <Row className="preview_descriptionRow">
                  <Col componentClass={ControlLabel} sm={4}>
                    説明文
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      name="description"
                      componentClass="textarea"
                      rows="3"
                      placeholder=""
                      value={state.description}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
                <Row className="previewStreamerComment">
                  <Col componentClass={ControlLabel} sm={4}>
                    配信開始時の固定コメント
                  </Col>
                  <Col sm={8}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControl
                        className="streamer-name"
                        maxLength="10"
                        name="name"
                        onChange={(e) =>
                          this.setState({ previewStreamerName: e.target.value })
                        }
                        placeholder="名前"
                        type="text"
                        value={state.previewStreamerName}
                      />
                      ：
                      <FormControl
                        className="streamer-text"
                        maxLength="100"
                        name="text"
                        onChange={(e) =>
                          this.setState({ previewStreamerText: e.target.value })
                        }
                        placeholder="コメント..."
                        type="text"
                        value={state.previewStreamerText}
                      />
                    </div>
                    <div>
                      {MAX_LENGTH_OF_STREAMER_COMMENT <
                        streamerCommentLength && (
                        <p className="error messages_fixedMessageFormError">
                          名前とコメントの合計全角文字数が32文字を超えてしまう場合、端末によっては全ての文字が表示されない恐れがあるため、文章の文字数を減らしてください。
                        </p>
                      )}
                      {!state.previewStreamerName &&
                        state.previewStreamerText && (
                          <p className="error messages_fixedMessageFormError">
                            名前を入力してください。（名前が未入力の場合、コメントは表示されません。）
                          </p>
                        )}
                    </div>
                  </Col>
                </Row>
                <div className="formcaption" style={{ margin: "0 0 0 auto" }}>
                  <p>
                    配信開始後に配信上の固定コメントは変更できません。
                    再配信する場合は変更が反映されます。
                  </p>
                </div>
                <Row style={{height: 50}}>
                  <Col componentClass={ControlLabel} sm={4}>
                    公開設定
                  </Col>
                  <Col sm={8} style={{ marginTop: -12 }}>
                    <Radio
                      inline
                      name="releaseOption"
                      value="public"
                      checked={!state.unlist && !state.privateMode}
                      onChange={this.onReleaseOptionChange}
                    >
                      {" "}
                      公開{" "}
                    </Radio>
                    <Radio
                      inline
                      name="releaseOption"
                      value="unlist"
                      checked={state.unlist}
                      onChange={this.onReleaseOptionChange}
                    >
                      {" "}
                      限定公開{" "}
                    </Radio>
                    <Radio
                      inline
                      name="releaseOption"
                      value="privateMode"
                      checked={state.privateMode}
                      onChange={this.onReleaseOptionChange}
                    >
                      {" "}
                      非公開{" "}
                    </Radio>
                  </Col>
                </Row>

                {state.surveyPermit && (
                  <Row style={{height: 50}}>
                    <Col componentClass={ControlLabel} sm={4}>
                      アンケート設定
                    </Col>
                    <Col sm={8} style={{ marginTop: -12 }}>
                      <Radio
                        inline
                        name="surveyOption"
                        value="ON"
                        checked={state.surveyMode}
                        onChange={this.onSurveyOptionChange}
                      >
                        {" "}
                        ON{" "}
                      </Radio>
                      <Radio
                        inline
                        name="surveyOption"
                        value="OFF"
                        checked={!state.surveyMode}
                        onChange={this.onSurveyOptionChange}
                      >
                        {" "}
                        OFF{" "}
                      </Radio>
                    </Col>
                  </Row>
                )}
                <Row style={{height: 50}}>
                  <Col componentClass={ControlLabel} sm={4}>
                    DM設定
                  </Col>
                  <Col sm={8} style={{ marginTop: -12 }}>
                    <Radio
                      inline
                      value="ON"
                      checked={state.dmMode}
                      onChange={this.onDmOptionChange}
                    >
                      {" "}
                      ON{" "}
                    </Radio>
                    <Radio
                      inline
                      value="OFF"
                      checked={!state.dmMode}
                      onChange={this.onDmOptionChange}
                    >
                      {" "}
                      OFF{" "}
                    </Radio>
                  </Col>
                </Row>
                <Row style={{height: 50}}>
                  <Col componentClass={ControlLabel} sm={4}>
                    X投稿設定
                  </Col>
                  <Col sm={8} style={{ marginTop: -12 }}>
                    <Radio
                      inline
                      value="ON"
                      checked={state.xPostMode}
                      onChange={this.onXPostOptionChange}
                    >
                      {" "}
                      ON{" "}
                    </Radio>
                    <Radio
                      inline
                      value="OFF"
                      checked={!state.xPostMode}
                      onChange={this.onXPostOptionChange}
                    >
                      {" "}
                      OFF{" "}
                    </Radio>
                  </Col>
                </Row>
                {state.xPostMode && <Row className="preview_descriptionRow">
                  <Col componentClass={ControlLabel} sm={4}>
                    X投稿本文 (80文字以内)
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      name="xPostText"
                      componentClass="textarea"
                      rows="3"
                      placeholder=""
                      value={state.xPostText}
                      onChange={this.onChange}
                      maxLength={80}
                    />
                  </Col>
                </Row>}
                {state.xPostMode && <Row className="preview_descriptionRow">
                  <Col componentClass={ControlLabel} sm={4}>
                    X投稿ハッシュタグ (30文字以内)
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      name="xPostHashTag"
                      componentClass="textarea"
                      rows="3"
                      placeholder="半角カンマ「,」区切りで複数個入力(#不要)"
                      value={state.xPostHashTag}
                      onChange={this.onChange}
                      maxLength={30}
                    />
                  </Col>
                </Row>}
                {state.xPostMode && 
                <>
                  <a className="mr10 normalBtn largeBtn" target="_blank" rel="noopener noreferrer" href={`https://x.com/intent/tweet?text=${encodeURIComponent(`${state.xPostText}\nhttps://${state.shops[0].domain}\n\n${state.xPostHashTag ? state.xPostHashTag.split(',').map(word => `#${word.trim()}`).join(' ') : ''}`)}`}>X投稿画面プレビュー</a>
                  <div className="formcaption">
                    <p>※実際のURLはライブページのURLが設定されます</p>
                  </div>
                </>
                }
                <Row style={{height: 50}}>
                  <Col componentClass={ControlLabel} sm={4}>
                    テロップ表示設定{state.showFullTelop}
                  </Col>
                  <Col sm={8} style={{ marginTop: -12 }}>
                    <Radio
                      inline
                      name="telopOption"
                      value="showTelop"
                      checked={state.showTelop}
                      onChange={this.onTelopOptionChange}
                    >
                      {" "}
                      右上表示{" "}
                    </Radio>
                    <Radio
                      inline
                      name="telopOption"
                      value="showFullTelop"
                      checked={state.showFullTelop}
                      onChange={this.onTelopOptionChange}
                    >
                      {" "}
                      全画面表示{" "}
                    </Radio>
                    <Radio
                      inline
                      name="telopOption"
                      value="buyButton"
                      checked={state.buyButton}
                      onChange={this.onTelopOptionChange}
                    >
                      {" "}
                      今すぐ購入{" "}
                    </Radio>
                    <Radio
                      inline
                      name="telopOption"
                      value="invisible"
                      checked={
                        !state.showTelop &&
                        !state.showFullTelop &&
                        !state.buyButton
                      }
                      onChange={this.onTelopOptionChange}
                    >
                      非表示
                    </Radio>
                  </Col>
                </Row>
                <Row>
                  <Col smOffset={4} sm={8}>
                    <Checkbox
                      name="archiveComment"
                      checked={state.archiveComment}
                      onChange={this.onChangeCheckbox}
                    >
                      コメントを表示する
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col smOffset={4} sm={8}>
                    <Checkbox
                      name="ctaButton"
                      checked={state.ctaButton}
                      onChange={this.onChangeCheckbox}
                    >
                      ライブ画面の左上に商品を表示する
                    </Checkbox>
                  </Col>
                </Row>
                {model.ENABLE_LIKE && (
                  <Row>
                    <Col smOffset={4} sm={8}>
                      <Checkbox
                        name="likeCountFlag"
                        checked={state.likeCountFlag}
                        disabled={state.isTest}
                        className={state.isTest ? "disabled" : null}
                        onChange={this.onChangeCheckbox}
                      >
                        ライブ画面上にライクカウントを表示する
                      </Checkbox>
                    </Col>
                  </Row>
                )}
                {model.ENABLE_LIKE && (
                  <Row>
                    <Col smOffset={4} sm={8}>
                      <Checkbox
                        name="viewsFlag"
                        checked={state.viewsFlag}
                        disabled={state.isTest}
                        className={state.isTest ? "disabled" : null}
                        onChange={this.onChangeCheckbox}
                      >
                        ライブ画面上に再生数を表示する
                      </Checkbox>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col smOffset={4} sm={8}>
                    <Checkbox
                      name="countDownFlag"
                      checked={state.countDownFlag}
                      onChange={this.onChangeCheckbox}
                    >
                      ライブ画面上にカウントダウンを表示する
                    </Checkbox>
                  </Col>
                </Row>
                {state.onlyLivePermit && (
                  <Row className="preview_archiveImageUrl">
                    <Col componentClass={ControlLabel} sm={4}>
                      アーカイブ画像用リンク
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="archive-image-url"
                        name="archiveImageUrl"
                        onChange={(e) =>
                          this.setState({ archiveImageUrl: e.target.value })
                        }
                        placeholder="URL"
                        type="text"
                        value={state.archiveImageUrl}
                      />
                    </Col>
                  </Row>
                )}
              </FormGroup>
              {this.props.edit && (
                <div>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={4}>
                      動画のダウンロード
                      <br />
                      と削除
                    </Col>
                    <Col sm={8}>
                      <ul>
                        <li className="dtTablelist">
                          <span className="dtSessionDateTimeCell inlineBlock">
                            更新日
                          </span>
                          <span className="dtSessionActionCell inlineBlock">
                            ダウンロード
                          </span>
                          <span className="dtSessionDeleteCell inlineBlock">
                            削除
                          </span>
                        </li>
                      </ul>
                      {state.sessions
                        .filter(
                          (session) => session.stabilized && !session.deleted
                        )
                        .map((session, index) => (
                          <ul key={session.archiveId}>
                            <li className="ddTablelist">
                              <span className="ddSessionDateTimeCell inlineBlock">
                                {moment(session.created.toDate()).format(
                                  "YYYY年M月D日"
                                )}
                                <br />
                                {moment(session.created.toDate()).format(
                                  "HH:mm"
                                )}
                                {index === 0 && (
                                  <div className="tagmust">表示中</div>
                                )}
                              </span>
                              <span className="ddSessionActionCell inlineBlock">
                                {this.getDownloadArchiveUrl(session) ? (
                                  <a
                                    onClick={() => this.download(session)}
                                    href="#/"
                                  >
                                    ダウンロード
                                  </a>
                                ) : (
                                  <span>ダウンロード準備中</span>
                                )}
                              </span>
                              <span className="ddSessionDeleteCell inlineBlock">
                                {index !== 0 && (
                                  <img
                                    src={deleteIcon}
                                    alt="poster"
                                    onClick={() => this.onDeleteVideo(session)}
                                  />
                                )}
                              </span>
                            </li>
                          </ul>
                        ))}
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col
                        componentClass={ControlLabel}
                        className="displayBlock"
                        sm={4}
                      >
                        動画のアップロード (720x1280)
                        <div className="file-suggest-size">（1.5GB以下）</div>
                      </Col>
                      <Col sm={8}>
                        <Dropzone
                          name="videoFile"
                          onDrop={this.onVideoFileChange}
                          disabled={this.state.videoUploading}
                          accept={{ "video/mp4": [] }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="dropzoneContainer">
                              <div className="dropzone" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>ファイルアップロード</p>
                                <input
                                  type="button"
                                  value="ファイルを選択"
                                  onChange={this.onVideoFileChange}
                                ></input>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {state.videoFile && <p>{state.videoFile.name}</p>}
                      </Col>
                    </Row>
                    <Row>
                      <Col smOffset={4} sm={8}>
                        <button
                          type="button"
                          className="strongBtn"
                          onClick={this.onSubmitVideoFile}
                          disabled={state.videoUploading}
                        >
                          アップロード
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col smOffset={4} sm={8}>
                        <p className="green">{this.state.videoMessage}</p>
                        <p>{this.state.videoProgress}</p>
                        {this.state.videoErrors.length > 0 && (
                          <div className="error">
                            <strong>動画のアップロードエラー：</strong>
                            <br />
                            <ul>
                              {this.state.videoErrors.map((e, i) => (
                                <li key={i}>{e.message}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
              )}
              {!state.isTest && (
                <FormGroup controlId="image">
                  <Col
                    componentClass={ControlLabel}
                    className="displayBlock"
                    sm={4}
                  >
                    バナー画像（PC）
                    <div className="file-suggest-size">（200KB以下）</div>
                  </Col>
                  <Col sm={8} className="hiddenOverflow">
                    <Dropzone
                      onDrop={this.onBannerChange}
                      accept={{ "image/*": [] }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzoneContainer">
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>ファイルアップロード</p>
                            <input
                              type="button"
                              value="ファイルを選択"
                              onChange={this.onBannerChange}
                            ></input>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {state.banner && (
                      <ul>
                        <li className="liveBanner">
                          <img src={state.banner} alt="banner" width="200" />
                          <a onClick={() => this.removeBanner()} href="#/">
                            <img
                              src={closeIcon}
                              alt="削除する"
                              className="close"
                            />
                          </a>
                        </li>
                      </ul>
                    )}
                  </Col>
                </FormGroup>
              )}
              {!state.isTest && (
                <FormGroup controlId="image">
                  <Col
                    componentClass={ControlLabel}
                    className="displayBlock"
                    sm={4}
                  >
                    バナー画像（モバイル）
                    <div className="file-suggest-size">（200KB以下）</div>
                  </Col>
                  <Col sm={8} className="hiddenOverflow">
                    <Dropzone
                      onDrop={this.onSpBannerChange}
                      accept={{ "image/*": [] }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzoneContainer">
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>ファイルアップロード</p>
                            <input
                              type="button"
                              value="ファイルを選択"
                              onChange={this.onSpBannerChange}
                            ></input>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {state.spBanner && (
                      <ul>
                        <li className="liveBanner">
                          <img src={state.spBanner} alt="banner" width="200" />
                          <a onClick={() => this.removeSpBanner()} href="#/">
                            <img
                              src={closeIcon}
                              alt="削除する"
                              className="close"
                            />
                          </a>
                        </li>
                      </ul>
                    )}
                  </Col>
                </FormGroup>
              )}
              <FormGroup controlId="telops">
                <Row>
                  <Col
                    componentClass={ControlLabel}
                    className="displayBlock"
                    sm={4}
                  >
                    ライブに表示するテロップ（10枚まで）
                    <div className="file-suggest-size">（1画像100KB以下）</div>
                  </Col>
                  <Col sm={8} className="hiddenOverflow">
                    {state.liveTelops.length < 10 && (
                      <Dropzone
                        onDrop={this.onTelopChange}
                        accept={{ "image/*": [] }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzoneContainer">
                            <div className="dropzone" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>ファイルアップロード</p>
                              <input
                                type="button"
                                value="ファイルを選択"
                                onChange={this.onTelopChange}
                              ></input>
                              
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} />
                  <Col sm={8}>
                    <ul>
                      {state.liveTelops.map((telop, index) => (
                        <li key={index} className="liveTelop">
                          <img src={telop.image} alt="telop" />
                          <FormControl
                            className="telop-url"
                            name={`telop${index}`}
                            onChange={(e) => {
                                const liveTelops = [...this.state.liveTelops];
                                liveTelops[index] = { ...liveTelops[index], url: e.target.value };
                                this.setState({ liveTelops })
                              }
                            }
                            placeholder="URL"
                            type="text"
                            value={state.liveTelops[index].url}
                          />
                          <a onClick={() => this.removeTelop(telop)} href="#/">
                            <img
                              src={closeIcon}
                              alt="削除する"
                              className="close"
                            />
                          </a>
                          {index > 0 && (
                            <a
                              onClick={() => this.moveBackTelop(telop)}
                              href="#/"
                            >
                              <img
                                src={moveBackIcon}
                                alt="前に移動する"
                                className="moveBack"
                              />
                            </a>
                          )}
                          {index < state.liveTelops.length - 1 && (
                            <a
                              onClick={() => this.moveNextTelop(telop)}
                              href="#/"
                            >
                              <img
                                src={moveNextIcon}
                                alt="次に移動する"
                                className="moveNext"
                              />
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup controlId="items">
                <Col componentClass={ControlLabel} sm={4}>
                  ライブに表示する商品の初期設定
                </Col>
                <Col sm={8}>
                  <ul>
                    {state.liveItems.map((item, index) => (
                      <li key={item.id} className="liveItem">
                        <img
                          src={item.image}
                          alt="item"
                          className="preview_liveItemImage"
                        />
                        <span>{item.name}</span>
                        <a onClick={() => this.removeItem(item)} href="#/">
                          <img
                            src={closeIcon}
                            alt="削除する"
                            className="close"
                          />
                        </a>
                        {index > 0 && (
                          <a onClick={() => this.moveBackItem(item)} href="#/">
                            <img
                              src={moveBackIcon}
                              alt="前に移動する"
                              className="moveBack"
                            />
                          </a>
                        )}
                        {index < state.liveItems.length - 1 && (
                          <a onClick={() => this.moveNextItem(item)} href="#/">
                            <img
                              src={moveNextIcon}
                              alt="次に移動する"
                              className="moveNext"
                            />
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </Col>
              </FormGroup>
              <FormGroup controlId="shop">
                <Col componentClass={ControlLabel} sm={4}>
                  ショップの選択
                </Col>
                <Col sm={8}>
                  {state.shops.length > 0 && (
                    <DropdownButton
                      title={state.shops[state.shopIndex].name}
                      id="shop"
                    >
                      {state.shops.map((shop, i) => (
                        <MenuItem
                          key={i}
                          eventKey={i}
                          active={i === state.shopIndex}
                          onSelect={this.onSelectShop}
                        >
                          {shop.name}
                        </MenuItem>
                      ))}
                    </DropdownButton>
                  )}
                </Col>
              </FormGroup>
            </form>
            <ControlLabel>商品の選択</ControlLabel>
            {state.shops.length > 0 && (
              <ItemSearch
                shopId={state.shops[state.shopIndex].id}
                onAddItem={this.addItem}
                liveItems={state.liveItems}
              />
            )}
          </div>
          <div className="fixFooter">
            <div className="fixFooterInner">
              <div className="fixFooterBtnArea">
                {state.success && (
                  <p className="liveBookingMessage done">
                    ライブが更新されました
                  </p>
                )}
                {(state.errors.nameIsEmpty ||
                  state.errors.nameIsTooLong ||
                  state.errors.imageIsEmpty ||
                  state.errors.datetimeIsEmpty) && (
                  <p className="liveBookingMessage error">
                    ライブの情報を全部入力してください。
                  </p>
                )}
                {!state.isTest && this.props.edit && (
                  <Button
                    type="button"
                    className="strongDeleteButton"
                    onClick={this.deleteLive}
                  >
                    削除する
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={this.setLive}
                  className="strongBtn"
                  disabled={state.processing}
                >
                  {this.props.edit ? "更新する" : "登録する"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
