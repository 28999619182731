import firebase from './firebase';

const db = firebase.firestore();

export default class Utilities {
  /**
   * Count number of characters as hankaku.
   * Estimate japanese text display width by checking each characters whether zenkaku or hankaku.
   *
   * @param {string} text - text to count number of characters as hankaku
   * @return {string} Number of characters as hankaku
   */
  static countNumberOfCharactersAsHankaku(text) {
    if (!text) {
      return 0;
    }
    let number = 0;
    for (let c of text) {
      // eslint-disable-next-line no-control-regex
      if (c.match(/[\x01-\x7E]/) || c.match(/[\uFF65-\uFF9F]/)) {
        number += 1;
      } else {
        number += 2;
      }
    }
    return number;
  }

  static isIE () {
    const isIE10 = window.navigator.appName === 'Microsoft Internet Explorer';
    const isIE11 = window.navigator.appName === 'Netscape' && window.navigator.userAgent.toLowerCase().indexOf('trident') > -1;

    return isIE10 || isIE11;
  }

  static countTotalPurchasers = (purchaseData) => {
    const reducer = (accumulator, currentValue) => Object.assign(accumulator, currentValue);
    for (const type of ['preview', 'live', 'archive', 'liveArchive']) {
      purchaseData[type].liveNum['total'] = Object.keys(Object.values(purchaseData[type].liveNum).reduce(reducer, {})).length;
      purchaseData[type].allNum['total'] = Object.keys(Object.values(purchaseData[type].allNum).reduce(reducer, {})).length;
    }
    return;
  }

  static validateNumber = (value, maxInt, maxFloat = false) => {
    if (!maxFloat) {
      if (value && value.length > maxInt) return '整数' + maxInt +'桁以下でご入力ください';
    } else {
      const number = String(value).split('.');
      if (number[0] && number[0].length > maxInt) return '整数' + maxInt +'桁以下でご入力ください';
      if (number[1] && number[1].length > maxFloat) return '小数' + maxFloat +'桁以下でご入力ください';
    }
  }

  static getAccountPermission = async (uid, permission) => {
    if (!uid) {
      return {};
    }
    const data = await db.collection('shops').where('uid', '==', uid).get();
    if (!data.empty) {
      const shop = data.docs[0].data();
      permission.seller = shop.sellerPermit === 'OFF' ? false : true;
      permission.reception = shop.receptionPermit === 'ON' ? true : false;
      return permission;
    }
    return {};
  }
}
