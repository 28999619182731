import FileSaver from 'file-saver';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  Nav,
  NavItem,
  Checkbox,
} from 'react-bootstrap';
import moment from 'moment';

import './App.css';
import StreamerNav from './StreamerNav';
import firebase from './firebase';
import model from './Model';
import Utilities from './Utilities';

const db = firebase.firestore();

const MAX_LENGTH_OF_STREAMER_COMMENT = 64;

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.nComments = 10000;
    this.state = {
      live: null,
      session: null,
      streamerName: '',
      streamerText: '',
      streamerComments: [],
      /** 入力予定のコメントが固定コメントかどうか */
      isStremerComment: false,
      viewerComments: [],
      privateComments: [],
      redirect: false
    };
  }

  componentDidMount() {
    this.uid = firebase.auth().currentUser.uid;
    this.liveId = this.props.match.params.id;
    this.liveRef = db.collection('lives').doc(this.liveId);
    this.liveRef.get().then((liveSnapshot) => {
      if (liveSnapshot.exists) {
        const live = liveSnapshot.data();
        this.setState({ live });
        const streamerCommentEventsRef = this.liveRef.collection('streamer-comment-events').orderBy('created', 'desc').limit(this.nComments);
        this.offStreamerCommentEvents = streamerCommentEventsRef.onSnapshot((streamerCommentEventsSnapshot) => {
          const streamerComments = streamerCommentEventsSnapshot.docs.map((streamerCommentEventSnapshot) => streamerCommentEventSnapshot.data());
          this.setState({ streamerComments: streamerComments.filter((streamerComment) => !!streamerComment.created) });
        });
        db.collection('streamers').doc(this.uid).get().then((streamerSnapshot) => {
          if (streamerSnapshot.exists) {
            const streamer = streamerSnapshot.data();
            this.setState({ streamerName: streamer.name });
          }
        });

        const viewerCommentEventsRef = this.liveRef.collection('viewer-comment-events').orderBy('created', 'desc').limit(this.nComments);
        this.offViewerCommentEvents = viewerCommentEventsRef.onSnapshot((viewerCommentEventsSnapshot) => {
          const viewerComments = viewerCommentEventsSnapshot.docs.map((viewerCommentEventSnapshot) => viewerCommentEventSnapshot.data());
          this.setState({ viewerComments: viewerComments.filter((viewerComment) => !!viewerComment.created) });
        });

        const privateCommentEventsRef = this.liveRef.collection('viewer-private-comment-events').orderBy('created', 'desc').limit(this.nComments);
        this.offPrivateCommentEvents = privateCommentEventsRef.onSnapshot((privateCommentEventsSnapshot) => {
          const privateComments = privateCommentEventsSnapshot.docs.map((privateCommentEventSnapshot) => privateCommentEventSnapshot.data());
          this.setState({ privateComments: privateComments.filter((privateComment) => !!privateComment.created) });
        });
      }
    });
    this.offSessions = (
      db
        .collection('sessions')
        .where('liveId', '==', this.liveId)
        .where('stabilized', '!=', null)
        .orderBy('stabilized', 'desc')
        .orderBy('created', 'desc')
        .limit(1)
    ).onSnapshot((sessionsSnapshot) => {
      if (!sessionsSnapshot.empty) {
        const session = sessionsSnapshot.docs[0].data();
        if (session.uid === this.uid) {
          this.setState({ session });
        } else {
          this.setState({ redirect: true });
        }
      }
    });
  }

  componentWillUnmount() {
    if (this.offStreamerCommentEvents) {
      this.offStreamerCommentEvents();
    }
    if (this.offViewerCommentEvents) {
      this.offViewerCommentEvents();
    }
    if (this.offPrivateCommentEvents) {
      this.offPrivateCommentEvents();
    }
    if (this.offSessions) {
      this.offSessions();
    }
  }

  onChangeIsStremerComment = () => {
    this.setState({ isStremerComment: !this.state.isStremerComment});
  };

  addStreamerComment = () => {
    const streamerCommentEventRef = this.liveRef.collection('streamer-comment-events').doc();
    streamerCommentEventRef.set({
      id: streamerCommentEventRef.id,
      uid: this.uid,
      name: this.state.streamerName,
      text: this.state.streamerText,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    });
    this.setState({ streamerText: '' });
  };

  /** 固定コメントじゃない方でコメントする */
  addCommentAsViewer = () => {
    const doc = db.collection(`lives/${this.liveId}/viewer-comment-events`).doc();
    doc.set({
      id: doc.id,
      uid: this.uid,
      name: this.state.streamerName,
      text: this.state.streamerText,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    });
    this.setState({ streamerText: '' });
  };

  addComment = () => {
    if (this.state.isStremerComment) {
      this.addStreamerComment();
    } else {
      this.addCommentAsViewer();
    }
  };

  allMessages = () => {
    const {
      session,
      streamerComments,
      viewerComments,
      privateComments
    } = this.state;
    return [
      ...streamerComments
        .filter((comment) => session && (session.originalCreated || session.created) <= comment.created)
        .map((comment) => ({...comment, isStreamerComment: true })),
      ...viewerComments
        .filter((comment) => session && (session.originalCreated || session.created) <= comment.created)
        .map((comment) => ({...comment, isStreamerComment: false })),
      ...privateComments
        .filter((comment) => session && (session.originalCreated || session.created) <= comment.created)
        .map((comment) => ({...comment, isStreamerComment: false })),
    ].sort((a, b) => a.created - b.created).reverse();
  }

  delete = (message) => {
    if (message.isStreamerComment) {
      this.liveRef.collection('streamer-comment-events').doc(message.id).delete();
    } else if (message.isPrivate) {
      this.liveRef.collection('viewer-private-comment-events').doc(message.id).delete();
    }else{
      this.liveRef.collection('viewer-comment-events').doc(message.id).delete();
    }
  }

  download = () => {
    const lines = this.allMessages().map((message) => {
      const pin = message.isStreamerComment ? '📍 ' : message.isPrivate ? '📩 ' : '';
      return `${moment(message.created.toDate()).utcOffset('+0900').format()}\t${message.name}\t${pin}${message.text}\r\n`;
    });
    const blob = new Blob(lines, { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, `${this.liveId}_comments.tsv`);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/streamer/previews" />;
    }
    const streamerComment = `${this.state.streamerName}：${this.state.streamerText}`;
    const streamerCommentLength = Utilities.countNumberOfCharactersAsHankaku(streamerComment);
    return (
      <div>
        <StreamerNav permission={this.props.permission}/>
        <div className="content messages">
          <Nav bsStyle="pills" className="detail-nav nav-m-bk">
            <NavItem href="/streamer/previews" active>ライブ予約一覧</NavItem>
            <li className="detailNav_navItemChild">コメント管理</li>
            <NavItem href="/streamer/previews/new">新しいライブ予約</NavItem>
            <NavItem href="/streamer/previews/trash">削除済みライブ</NavItem>
          </Nav>
          <div className="messages_titleArea">
            <h2>コメント一覧</h2>
            <Button type="button" onClick={this.download} className="normalBtn">コメントtsvダウンロード</Button>
          </div>
          {this.state.live && (
            <div>
              <span className="live_titleLabel">ID : {this.state.live.id} / タイトル : {this.state.live.name}</span>
            </div>)}
          <div className="card">
            {this.state.live && this.state.session && this.state.session.live && (
              <div className="mb20">
                <Form inline>
                  <FormGroup className="messages_fixedMessageFormGroup">
                    <FormControl
                      className="streamer-name"
                      maxLength="10"
                      name="name"
                      onChange={(e) => this.setState({ streamerName: e.target.value })}
                      placeholder="名前"
                      type="text"
                      value={this.state.streamerName}
                    />
                    ：
                    <FormControl
                      className="streamer-text"
                      maxLength="100"
                      name="text"
                      onChange={(e) => this.setState({ streamerText: e.target.value })}
                      placeholder="コメント..."
                      type="text"
                      value={this.state.streamerText}
                    />
                    <Button
                      className="normalBtn"
                      disabled={!(this.state.streamerName && this.state.streamerText)}
                      onClick={this.addComment}
                    >
                      送信
                    </Button>
                    <Checkbox
                      name="isStremerComment"
                      className="is-streamer-comment"
                      checked={this.state.isStremerComment}
                      onChange={() => this.onChangeIsStremerComment()}
                    >
                      固定コメント
                    </Checkbox>
                  </FormGroup>
                </Form>
                <div className='messages_fixedMessageFormGroup fix_comment'>
                  <span className="for_space"></span>
                  <span className="messages_fixedCommentDescription">固定コメントのチェックボックスをONにして送信されたコメントはコメント欄の下部に固定表示されます</span>
                </div>
                {MAX_LENGTH_OF_STREAMER_COMMENT < streamerCommentLength && (
                  <p className="error messages_fixedMessageFormError">
                    名前とコメントの合計全角文字数が32文字を超えてしまう場合、端末によっては全ての文字が表示されない恐れがあるため、文章の文字数を減らして投稿してください。
                  </p>
                )}
              </div>
            )}
            <ul>
              <li className="dtTablelist">
                <strong className="messages_dateTimeCell">投稿日時</strong>
                <strong className="messages_nameCell">名前</strong>
                <strong className="messages_textCell">内容</strong>
                <strong className="messages_statusCell">ステータス</strong>
              </li>
              <ul className="tableScrollArea">
                {this.allMessages().map((message) => (
                  <li key={message.id} className="ddTablelist">
                    <span className="messages_dateTimeCell">
                      {moment(message.created.toDate()).format('YYYY年M月D日HH:mm')}
                      {this.uid === message.uid && message.isStreamerComment && (
                        <div className="delivered">固定コメント</div>
                      )}
                    </span>
                    <span className="messages_nameCell">{message.name}</span>
                    <span className="messages_textCell">{message.isStreamerComment ? model.replaceLink(message.text) : message.text}</span>
                    <span className="messages_statusCell">{message.isPrivate ? 'DM' : ''}</span>
                    <a className="cautionBtn inlineBtn" href="#/" onClick={() => this.delete(message)}>削除</a>
                  </li>
                ))}
              </ul>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
