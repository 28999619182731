class Config {
  static CLOUD = 'https://us-central1-moffly-shop.cloudfunctions.net/';
  static PRIVATE_BUCKET = 'gs://moffly-shop';

  static FIREBASE_API_KEY = 'AIzaSyCsupZVlmrCrNhK4mYlByCd6ZwAR186gA8';
  static FIREBASE_AUTH_DOMAIN = 'moffly-shop.firebaseapp.com';
  static FIREBASE_DATABASE_URL = 'https://moffly-shop.firebaseio.com';
  static FIREBASE_PROJECT_ID = 'moffly-shop';
  static FIREBASE_STORAGE_BUCKET = 'moffly-images';
  static FIREBASE_MESSAGING_SENDER_ID = '778438726066';

  static ALGOLIA_APP_ID = 'FE9D46L9K7';
  static ALGOLIA_API_KEY = '009a399da458e58ac598e7f9a89621ef';
  static ALGOLIA_INDEX_PREFIX = 'Demo_';

  static SCENARIO_RECEPTIONIST = false;
};

export default Config;
