import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Nav,
  NavItem,
  Radio,
} from 'react-bootstrap';

import SellerNav from './SellerNav';
import firebase from './firebase';

import doneIcon from './img/icon/icon_done.svg';

const db = firebase.firestore();

const DefaultStyle = {
  itemListStyle: 'grid',
  itemImgRatio: '5x6',
  itemAlignStyle: 'left',
  fontFamily: 'Noto Sans JP, Arial, Helvetica, sans-serif',
  title: 'このライブで紹介しているアイテム',
  titleStyle: {
    color: '#000000',
    fontSize: 14,
    fontWeight: 400,
  },
  brandStyle: {
    color: '#000000',
    fontSize: 12,
    fontWeight: 400,
  },
  itemStyle: {
    color: '#000000',
    fontSize: 12,
    fontWeight: 400,
  },
  priceVisible: 'ON',
  priceStyle: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 400,
  },
  priceText: '(税込)',
  yenMark: true,
  subtitleStyle: {
    color: '#ff0000',
    fontSize: 14,
    fontWeight: 400,
  },
  aboutItemStyle: {
    color: '#333333',
    fontSize: 18,
    fontWeight: 400,
  },
  aboutItemText: 'この商品について',
  buyButtonStyle: {
    backgroundColor: '#666666',
    borderRadius: 16,
    color: '#ffffff',
    fontSize: 12,
    fontWeight: 700,
  },
  buyButtonText: '詳細を見る',
  liveListGridCellDateStyle: {
    color: '#999999',
    fontSize: 11,
    fontWeight: 400,
  },
  liveListGridCellNameStyle: {
    color: '#000000',
    fontSize: 12,
    fontWeight: 400,
  },
  messageStyle: 'whiteColorMessage',
  countDownPosition: 'middle',
  countDownFont: 'Noto Sans JP, sans-serif',
  countDownColor: '#FFFFFF',
  saleIconStyle: {
    text: 'SALE',
    color: '#FF0000',
    backgroundColor: '#FFFFFF',
    fontSize: 8,
    fontWeight: 400,
  },
  salePriceStyle: {
    color: '#000000',
    fontSize: 14,
    fontWeight: 400,
  },
  cancelledPriceStyle: {
    color: '#969696',
    fontSize: 12,
    fontWeight: 300,
  },
  ngwords : '',
};

export default class Theme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      succeeded: false,
      failedToCreate: false,
      failedToUpdate: false,
      failedToDelete: false,
      loading: false,
      id: '',
      ...DefaultStyle,
      redirect: false,
    };
  }

  componentDidMount() {
    this.uid = firebase.auth().currentUser.uid;
    if (!this.props.edit) {
      return;
    }
    this.setState({ loading: true });
    db.collection('admins').doc(this.uid).get().then((admin) => {
      db.collection('themes').doc(this.props.match.params.id).get().then((documentSnapshot) => {
        const theme = documentSnapshot.data();
        if (theme.uid === this.uid  || admin.exists) {
          this.setState({...this.state, ...theme, ...{ loading: false }});
        } else {
          this.setState({ redirect: true });
        }
      });
    })
  }

  normalizeText(value) {
    const text = value.trim();
    return text;
  }

  normalizeNumber(value) {
    const text = value.trim();
    if (!text) {
      return '';
    }
    return Number(text);
  }

  validate() {
    const errors = { count: 0 };
    const warnings = { count: 0 };
    const state = this.state
    const inputs = [
      // name = 要素の名前 type = 入力要素のカテゴリー、表示するエラーごとにまとめる advised = 推奨値がある場合 state = プログラム上のstate
      {name: 'id', type: 'id', state: state.id},
      {name: 'fontFamily', type: 'fontFamily', state: state.fontFamily},
      {name: 'title', type: 'text', state: state.title},
      {name: 'titleStyle.color', type: 'color', state: state.titleStyle.color},
      {name: 'titleStyle.fontSize', type: 'fontSize', advised: 22 , state: state.titleStyle.fontSize},
      {name: 'titleStyle.fontWeight', type: 'fontWeight', state: state.titleStyle.fontWeight},
      {name: 'brandStyle.color', type: 'color', state: state.brandStyle.color},
      {name: 'brandStyle.fontSize', type: 'fontSize', advised: 16, state: state.brandStyle.fontSize},
      {name: 'brandStyle.fontWeight', type: 'fontWeight', state: state.brandStyle.fontWeight},
      {name: 'itemStyle.color', type: 'color', state: state.itemStyle.color},
      {name: 'itemStyle.fontSize', type: 'fontSize', advised: 18, state: state.itemStyle.fontSize},
      {name: 'itemStyle.fontWeight', type: 'fontWeight', state: state.itemStyle.fontWeight},
      {name: 'priceStyle.color', type: 'color', state: state.priceStyle.color},
      {name: 'priceStyle.fontSize', type: 'fontSize', advised: 12, state: state.priceStyle.fontSize},
      {name: 'priceStyle.fontWeight', type: 'fontWeight', state: state.priceStyle.fontWeight},
      {name: 'priceText', type: 'text', state: state.priceText},
      {name: 'subtitleStyle.color', type: 'color', state: state.subtitleStyle.color},
      {name: 'subtitleStyle.fontSize', type: 'fontSize', advised: 12, state: state.subtitleStyle.fontSize},
      {name: 'subtitleStyle.fontWeight', type: 'fontWeight', state: state.subtitleStyle.fontWeight},
      {name: 'aboutItemStyle.color', type: 'color', state: state.aboutItemStyle.color},
      {name: 'aboutItemStyle.fontSize', type: 'fontSize', state: state.aboutItemStyle.fontSize},
      {name: 'aboutItemStyle.fontWeight', type: 'fontWeight', state: state.aboutItemStyle.fontWeight},
      {name: 'aboutItemText', type: 'text', state: state.aboutItemText},
      {name: 'buyButtonStyle.backgroundColor', type: 'color', state: state.buyButtonStyle.backgroundColor},
      {name: 'buyButtonStyle.borderRadius', type: 'borderRadius', state: state.buyButtonStyle.borderRadius},
      {name: 'buyButtonStyle.color', type: 'color', state: state.buyButtonStyle.color},
      {name: 'buyButtonStyle.fontSize', type: 'fontSize', state: state.buyButtonStyle.fontSize},
      {name: 'buyButtonStyle.fontWeight', type: 'fontWeight', state: state.buyButtonStyle.fontWeight},
      {name: 'buyButtonText', type: 'text', state: state.buyButtonText},
      {name: 'liveListGridCellDateStyle.color', type: 'color', state: state.liveListGridCellDateStyle.color},
      {name: 'liveListGridCellDateStyle.fontSize', type: 'fontSize', state: state.liveListGridCellDateStyle.fontSize},
      {name: 'liveListGridCellDateStyle.fontWeight', type: 'fontWeight', state: state.liveListGridCellDateStyle.fontWeight},
      {name: 'liveListGridCellNameStyle.color', type: 'color', state: state.liveListGridCellNameStyle.color},
      {name: 'liveListGridCellNameStyle.fontSize', type: 'fontSize', state: state.liveListGridCellNameStyle.fontSize},
      {name: 'liveListGridCellNameStyle.fontWeight', type: 'fontWeight', state: state.liveListGridCellNameStyle.fontWeight},
      {name: 'countDownFont', type: 'countDownFont', state: state.countDownFont},
      {name: 'countDownColor', type: 'color', state: state.countDownColor},
      {name: 'saleIconStyle.text', type: 'text', state: state.saleIconStyle.text},
      {name: 'saleIconStyle.backgroundColor', type: 'color', state: state.saleIconStyle.backgroundColor},
      {name: 'saleIconStyle.color', type: 'color', state: state.saleIconStyle.color},
      {name: 'saleIconStyle.fontSize', type: 'fontSize', state: state.saleIconStyle.fontSize},
      {name: 'saleIconStyle.fontWeight', type: 'fontWeight', state: state.saleIconStyle.fontWeight},
      {name: 'salePriceStyle.fontSize', type: 'fontSize', state: state.salePriceStyle.fontSize},
      {name: 'salePriceStyle.fontWeight', type: 'fontWeight', state: state.salePriceStyle.fontWeight},
      {name: 'salePriceStyle.color', type: 'color', state: state.salePriceStyle.color},
      {name: 'cancelledPriceStyle.fontSize', type: 'fontSize', state: state.cancelledPriceStyle.fontSize},
      {name: 'cancelledPriceStyle.fontWeight', type: 'fontWeight', state: state.cancelledPriceStyle.fontWeight},
      {name: 'cancelledPriceStyle.color', type: 'color', state: state.cancelledPriceStyle.color},
      {name: 'ngwords', type: 'ngwords', state: state.ngwords},
  ]  

    inputs.forEach((key) => {  //エラー達を格納する箱を作成
      errors[key.name] = [];
      warnings[key.name] = [];
    })

    const ColorRegexp = new RegExp(
      /^(#[0-9a-f]{3}|#[0-9a-f]{6}|rgb\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\)|rgba\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3},[0-9.]{1,3}\))$/,
      'i'
    );

    for (const input of inputs) {
      if (input.type === 'color') { //色系の判定
        if (input.state && !ColorRegexp.test(input.state)) {
          errors[input.name].push('RGBまたはRGBA表記で入力してください');
          errors.count += 1;
        }
      }

      if (input.type === 'fontSize') { //フォントサイズの判定
        if (input.state && input.state > 100) {
          errors[input.name].push('100以下で入力してください');
          errors.count += 1;
        } else if (input.state && input.state > input.advised) {
          warnings[input.name].push(`${input.advised}以下の値が推奨です。${input.advised}より大きな値の場合、ライブ動画タグのdata-widthが720より小さい条件において、レイアウトの崩れが発生する可能性がございます。`)
          warnings.count += 1;
        }
      }

      if (input.type === 'fontWeight') { //フォントウェイトの判定
        if (input.state && input.state > 1000) {
          errors[input.name].push('1000以下で入力してください');
          errors.count += 1;
        }
      }

      if (input.type === 'text') { //テキストの判定
        if (input.state && input.state.length > 20) {
          errors[input.name].push('20文字以内で入力してください');
          errors.count += 1;
        }
      }
    }

    //以下類型化できないエラー
    if (!state.id) {
      errors['id'].push('入力してください');
      errors.count += 1;
    }

    if (!state.id.match(/^[a-z0-9-]{0,20}$/i)) {
      errors['id'].push('英数字、ハイフンのみで20文字以内で入力してください');
      errors.count += 1;
    }

    if (!state.fontFamily.match(/^.{0,255}$/i)) {
      errors['fontFamily'].push('255文字以内で入力してください');
      errors.count += 1;
    }

    if (state.buyButtonStyle.borderRadius && state.buyButtonStyle.borderRadius > 1000) {
      errors['buyButtonStyle.borderRadius'].push('1000以下で入力してください');
      errors.count += 1;
    }

    if (!state.countDownFont.match(/^.{0,255}$/i)) {
      errors['countDownFont'].push('255文字以内で入力してください');
      errors.count += 1;
    }

    return {
      errors,
      warnings,
    };
  }

  setTheme() {
    this.setState({
      processing: true,
      succeeded: false,
      failedToCreate: false,
      failedToUpdate: false,
      failedToDelete: false,
    });
    const state = this.state
    if (state.ngwords) {
      state.ngwords = state.ngwords.split(',').map(e => e.replace(/\s/g, '')).filter(e => e).join(',');
    }
    const uid = state.uid || firebase.auth().currentUser.uid;
    db.collection('themes').doc(state.id).set({
      uid: uid,
      id: state.id,
      itemListStyle: state.itemListStyle,
      itemImgRatio: state.itemImgRatio,
      itemAlignStyle: state.itemAlignStyle,
      fontFamily: state.fontFamily,
      title: state.title,
      titleStyle: {
        color: state.titleStyle.color,
        fontSize: state.titleStyle.fontSize,
        fontWeight: state.titleStyle.fontWeight,
      },
      brandStyle: {
        color: state.brandStyle.color,
        fontSize: state.brandStyle.fontSize,
        fontWeight: state.brandStyle.fontWeight,
      },
      itemStyle: {
        color: state.itemStyle.color,
        fontSize: state.itemStyle.fontSize,
        fontWeight: state.itemStyle.fontWeight,
      },
      priceStyle: {
        color: state.priceStyle.color,
        fontSize: state.priceStyle.fontSize,
        fontWeight: state.priceStyle.fontWeight,
      },
      priceVisible: state.priceVisible,
      priceText: state.priceText,
      yenMark: state.yenMark,
      subtitleStyle: {
        color: state.subtitleStyle.color,
        fontSize: state.subtitleStyle.fontSize,
        fontWeight: state.subtitleStyle.fontWeight,
      },
      aboutItemStyle: {
        color: state.aboutItemStyle.color,
        fontSize: state.aboutItemStyle.fontSize,
        fontWeight: state.aboutItemStyle.fontWeight,
      },
      aboutItemText: state.aboutItemText,
      buyButtonStyle: {
        backgroundColor: state.buyButtonStyle.backgroundColor,
        borderRadius: state.buyButtonStyle.borderRadius,
        color: state.buyButtonStyle.color,
        fontSize: state.buyButtonStyle.fontSize,
        fontWeight: state.buyButtonStyle.fontWeight,
      },
      buyButtonText: state.buyButtonText,
      liveListGridCellDateStyle: {
        color: state.liveListGridCellDateStyle.color,
        fontSize: state.liveListGridCellDateStyle.fontSize,
        fontWeight: state.liveListGridCellDateStyle.fontWeight,
      },
      liveListGridCellNameStyle: {
        color: state.liveListGridCellNameStyle.color,
        fontSize: state.liveListGridCellNameStyle.fontSize,
        fontWeight: state.liveListGridCellNameStyle.fontWeight,
      },
      messageStyle: state.messageStyle,
      countDownPosition: state.countDownPosition,
      countDownFont: state.countDownFont,
      countDownColor: state.countDownColor,
      saleIconStyle: {
        text: state.saleIconStyle.text,
        color: state.saleIconStyle.color,
        backgroundColor: state.saleIconStyle.backgroundColor,
        fontSize: state.saleIconStyle.fontSize,
        fontWeight: state.saleIconStyle.fontWeight,
      },
      salePriceStyle: {
        fontSize: state.salePriceStyle.fontSize,
        fontWeight: state.salePriceStyle.fontWeight,
        color: state.salePriceStyle.color,
      },
      cancelledPriceStyle: {
        fontSize:state.cancelledPriceStyle.fontSize,
        fontWeight:state.cancelledPriceStyle.fontWeight,
        color:state.cancelledPriceStyle.color,
      },
      ngwords: state.ngwords,

    }).then(() => {
      this.setState({
        succeeded: true,
      });
    }).catch((e) => {
      if (this.props.edit) {
        this.setState({
          failedToUpdate: true,
        });
      } else {
        this.setState({
          failedToCreate: true,
        });
      }
    });
    this.setState({processing: false});
  }

  deleteTheme() {
    this.setState({
      succeeded: false,
      failedToCreate: false,
      failedToUpdate: false,
      failedToDelete: false,
    });
    if (window.confirm('本当にこのテーマを削除しますか？')) {
      db.collection('themes').doc(this.state.id).delete().then(() => {
        document.location.pathname = '/themes/';
      }).catch((e) => {
        this.setState({
          failedToDelete: true,
        });
      });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/themes" />;
    }
    const { errors, warnings } = this.validate();

    const messages = (id) => { //エラーとワーニングを表示する
      return [
        ...errors[id].map((message) => <p className="error" key={message}>{message}</p>),
        ...warnings[id].map((message) => <p className="warning" key={message}>{message}</p>)
      ];
    };
    
    return (
      <div>
        <SellerNav permission={this.props.permission}/>
        <div className="content theme">
          <h2>テーマ管理</h2>
          <Nav bsStyle="pills" className="detail-nav nav-m-bk">
            <NavItem href="/themes" active={this.props.edit}>テーマ一覧</NavItem>
            {this.props.edit && <NavItem className="detailNav_navItemChild">編集</NavItem>}
            <NavItem href="/themes/new" active={!this.props.edit}>新しいテーマ</NavItem>
          </Nav>
          {this.state.loading ? (
            <div className="card">
              <p>読み込み中...</p>
            </div>
          ) : (
            <div className="pb10">
              <div className="card">
                <form className="form form-horizontal" >
                  <FormGroup controlId="id">
                    <Col sm={6} componentClass={ControlLabel}>
                      ID
                      {!this.props.edit && <div className="tagmust">必須</div>}
                    </Col>
                      {this.props.edit ? (
                        <Col sm={6}>
                          <div className="theme_idLabel">{this.state.id}</div>
                        </Col>
                      ) : (
                        <Col sm={6}>
                          <FormControl
                            name="id"
                            type="text"
                            placeholder="new-theme"
                            value={this.state.id}
                            onChange={(e) => this.setState({ id: this.normalizeText(e.target.value) })}
                            />
                          {messages("id")}
                        </Col>
                      )}
                  </FormGroup>
                  <FormGroup controlId="itemListStyle" value={this.state.itemListStyle}>
                    <Col sm={6} componentClass={ControlLabel}>アイテム表示</Col>
                    <Col sm={6}>
                      <Radio
                        name="itemListStyle"
                        checked={this.state.itemListStyle === 'grid'}
                        value={'grid'}
                        onChange={(e) => this.setState({ itemListStyle: e.target.value })}
                        inline>
                        grid
                      </Radio>
                      <Radio
                        name="itemListStyle"
                        checked={this.state.itemListStyle === 'list'}
                        value={'list'}
                        onChange={(e) => this.setState({ itemListStyle: e.target.value })}
                        inline>
                        list
                      </Radio>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="fontFamily">
                    <Col sm={6} componentClass={ControlLabel}>フォント</Col>
                    <Col sm={6}>
                      <FormControl
                        name="fontFamily"
                        type="text"
                        placeholder="Noto Sans JP, Arial, Helvetica, sans-serif"
                        value={this.state.fontFamily}
                        onChange={(e) => this.setState({ fontFamily: this.normalizeText(e.target.value) })}
                        className="theme_textInput-wide"
                        />
                      {messages('fontFamily')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="title">
                    <Col sm={6} componentClass={ControlLabel}>商品一覧タイトル</Col>
                    <Col sm={6}>
                      <FormControl
                        name="title"
                        type="text"
                        placeholder="このライブで紹介しているアイテム"
                        value={this.state.title}
                        onChange={(e) => this.setState({ title: this.normalizeText(e.target.value) })}
                        />
                      {messages('title')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="titleStyle.color">
                    <Col sm={6} componentClass={ControlLabel}>商品一覧タイトルの文字色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="titleStyle.color"
                        type="color"
                        placeholder="#000000"
                        value={this.state.titleStyle.color}
                        onChange={(e) => this.setState({ titleStyle: {...this.state.titleStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                        />
                      {messages('titleStyle.color')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="titleStyle.fontSize">
                    <Col sm={6} componentClass={ControlLabel}>商品一覧タイトルの文字サイズ (px)</Col>
                    <Col sm={6}>
                      <FormControl
                        name="titleStyle.fontSize"
                        type="number"
                        placeholder="12"
                        value={this.state.titleStyle.fontSize}
                        onChange={(e) => this.setState({ titleStyle: {...this.state.titleStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('titleStyle.fontSize')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="titleStyle.fontWeight">
                    <Col sm={6} componentClass={ControlLabel}>商品一覧タイトルの文字の太さ</Col>
                    <Col sm={6}>
                      <FormControl
                        name="titleStyle.fontWeight"
                        type="number"
                        placeholder="12"
                        value={this.state.titleStyle.fontWeight}
                        onChange={(e) => this.setState({ titleStyle: {...this.state.titleStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('titleStyle.fontWeight')}
                    </Col>
                  </FormGroup>
                  {this.state.itemListStyle === 'grid' && (
                  <FormGroup controlId="itemAlignStyle" value={this.state.itemAlignStyle}>
                    <Col sm={6} componentClass={ControlLabel}>商品情報テキストの整列</Col>
                    <Col sm={6}>
                      <Radio
                        name="itemAlignStyle"
                        checked={this.state.itemAlignStyle === 'left'}
                        value={'left'}
                        onChange={(e) => this.setState({ itemAlignStyle: e.target.value })}
                        inline>
                        左寄せ
                      </Radio>
                      <Radio
                        name="itemAlignStyle"
                        checked={this.state.itemAlignStyle === 'center'}
                        value={'center'}
                        onChange={(e) => this.setState({ itemAlignStyle: e.target.value })}
                        inline>
                        中央揃え
                      </Radio>
                      <Radio
                        name="itemAlignStyle"
                        checked={this.state.itemAlignStyle === 'right'}
                        value={'right'}
                        onChange={(e) => this.setState({ itemAlignStyle: e.target.value })}
                        inline>
                        右寄せ
                      </Radio>
                    </Col>
                  </FormGroup>)}
                  <FormGroup controlId="itemImgRatio" value={this.state.itemImgRatio}>
                    <Col sm={6} componentClass={ControlLabel}>商品画像の比率</Col>
                    <Col sm={6}>
                      <Radio
                        name="itemImgRatio"
                        checked={this.state.itemImgRatio === '5x6'}
                        value={'5x6'}
                        onChange={(e) => this.setState({ itemImgRatio: e.target.value })}
                        inline>
                        5:6
                      </Radio>
                      <Radio
                        name="itemImgRatio"
                        checked={this.state.itemImgRatio === '1x1'}
                        value={'1x1'}
                        onChange={(e) => this.setState({ itemImgRatio: e.target.value })}
                        inline>
                        1:1
                      </Radio>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="brandStyle.color">
                    <Col sm={6} componentClass={ControlLabel}>ブランド名の文字色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="brandStyle.color"
                        type="color"
                        placeholder="#000000"
                        value={this.state.brandStyle.color}
                        onChange={(e) => this.setState({ brandStyle: {...this.state.brandStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                        />
                      {messages('brandStyle.color')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="brandStyle.fontSize">
                    <Col sm={6} componentClass={ControlLabel}>ブランド名の文字サイズ (px)</Col>
                    <Col sm={6}>
                      <FormControl
                        name="brandStyle.fontSize"
                        type="number"
                        placeholder="12"
                        value={this.state.brandStyle.fontSize}
                        onChange={(e) => this.setState({ brandStyle: {...this.state.brandStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('brandStyle.fontSize')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="brandStyle.fontWeight">
                    <Col sm={6} componentClass={ControlLabel}>ブランド名の文字の太さ</Col>
                    <Col sm={6}>
                      <FormControl
                        name="brandStyle.fontWeight"
                        type="number"
                        placeholder="12"
                        value={this.state.brandStyle.fontWeight}
                        onChange={(e) => this.setState({ brandStyle: {...this.state.brandStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('brandStyle.fontWeight')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="itemStyle.color">
                    <Col sm={6} componentClass={ControlLabel}>アイテム名の文字色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="itemStyle.color"
                        type="color"
                        placeholder="#000000"
                        value={this.state.itemStyle.color}
                        onChange={(e) => this.setState({ itemStyle: {...this.state.itemStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                        />
                      {messages('itemStyle.color')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="itemStyle.fontSize">
                    <Col sm={6} componentClass={ControlLabel}>アイテム名の文字サイズ (px)</Col>
                    <Col sm={6}>
                      <FormControl
                        name="itemStyle.fontSize"
                        type="number"
                        placeholder="12"
                        value={this.state.itemStyle.fontSize}
                        onChange={(e) => this.setState({ itemStyle: {...this.state.itemStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('itemStyle.fontSize')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="itemStyle.fontWeight">
                    <Col sm={6} componentClass={ControlLabel}>アイテム名の文字の太さ</Col>
                    <Col sm={6}>
                      <FormControl
                        name="itemStyle.fontWeight"
                        type="number"
                        placeholder="12"
                        value={this.state.itemStyle.fontWeight}
                        onChange={(e) => this.setState({ itemStyle: {...this.state.itemStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('itemStyle.fontWeight')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="itemListStyle" value={this.state.itemListStyle}>
                    <Col sm={6} componentClass={ControlLabel}>価格表示</Col>
                    <Col sm={6}>
                      <Radio
                        name="priceVisible"
                        checked={this.state.priceVisible === 'ON'}
                        value={'ON'}
                        onChange={(e) => this.setState({ priceVisible: e.target.value })}
                        inline>
                        ON
                      </Radio>
                      <Radio
                        name="priceVisible"
                        checked={this.state.priceVisible === 'OFF'}
                        value={'OFF'}
                        onChange={(e) => this.setState({ priceVisible: e.target.value })}
                        inline>
                        OFF
                      </Radio>
                    </Col>
                  </FormGroup>
                  {this.state.priceVisible === 'ON' && 
                  <div>
                    <FormGroup controlId="priceStyle.color">
                      <Col sm={6} componentClass={ControlLabel}>価格の文字色</Col>
                      <Col sm={6}>
                        <FormControl
                          name="priceStyle.color"
                          type="color"
                          placeholder="#333333"
                          value={this.state.priceStyle.color}
                          onChange={(e) => this.setState({ priceStyle: {...this.state.priceStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                          />
                        {messages('priceStyle.color')}
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="priceStyle.fontSize">
                      <Col sm={6} componentClass={ControlLabel}>価格の文字サイズ (px)</Col>
                      <Col sm={6}>
                        <FormControl
                          name="priceStyle.fontSize"
                          type="number"
                          placeholder="14"
                          value={this.state.priceStyle.fontSize}
                          onChange={(e) => this.setState({ priceStyle: {...this.state.priceStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                          />
                        {messages('priceStyle.fontSize')}
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="priceStyle.fontWeight">
                      <Col sm={6} componentClass={ControlLabel}>価格の文字の太さ</Col>
                      <Col sm={6}>
                        <FormControl
                          name="priceStyle.fontWeight"
                          type="number"
                          placeholder="14"
                          value={this.state.priceStyle.fontWeight}
                          onChange={(e) => this.setState({ priceStyle: {...this.state.priceStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                          />
                        {messages('priceStyle.fontWeight')}
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="priceText">
                      <Col sm={6} componentClass={ControlLabel}>価格の末尾のテキスト</Col>
                      <Col sm={6}>
                        <FormControl
                          name="priceText"
                          type="text"
                          placeholder="(税込)"
                          value={this.state.priceText}
                          onChange={(e) => this.setState({ priceText: this.normalizeText(e.target.value) })}
                          />
                        {messages('priceText')}
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="yenMark">
                      <Col sm={6} componentClass={ControlLabel}>通貨表記</Col>
                      <Col sm={6}>
                        <Radio
                          name="yenMark"
                          checked={this.state.yenMark}
                          value={true}
                          onChange={(e) => this.setState({ yenMark: true })}
                          inline>
                          ¥
                        </Radio>
                        <Radio
                          name="yenMark"
                          checked={!this.state.yenMark}
                          value={false}
                          onChange={(e) => this.setState({ yenMark: false })}
                          inline>
                          円
                        </Radio>
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="salePriceStyle.color">
                      <Col sm={6} componentClass={ControlLabel}>セール価格の文字色</Col>
                      <Col sm={6}>
                        <FormControl
                          name="salePriceStyle.color"
                          type="color"
                          placeholder="#FFFFFF"
                          value={this.state.salePriceStyle.color}
                          onChange={(e) => this.setState({ salePriceStyle: {...this.state.salePriceStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                          />
                      </Col>
                      {messages('salePriceStyle.color')}
                    </FormGroup>
                    <FormGroup controlId="salePriceStyle.fontSize">
                      <Col sm={6} componentClass={ControlLabel}>セール価格の文字のサイズ (px)</Col>
                      <Col sm={6}>
                        <FormControl
                          name="salePriceStyle.fontSize"
                          type="number"
                          placeholder="12"
                          value={this.state.salePriceStyle.fontSize}
                          onChange={(e) => this.setState({ salePriceStyle: {...this.state.salePriceStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                          />
                      </Col>
                      {messages('salePriceStyle.fontSize')}
                    </FormGroup>
                    <FormGroup controlId="salePriceStyle.fontWeight">
                      <Col sm={6} componentClass={ControlLabel}>セール価格の文字の太さ</Col>
                      <Col sm={6}>
                        <FormControl
                          name="salePriceStyle.fontWeight"
                          type="number"
                          placeholder="12"
                          value={this.state.salePriceStyle.fontWeight}
                          onChange={(e) => this.setState({ salePriceStyle: {...this.state.salePriceStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                          />
                      </Col>
                      {messages('salePriceStyle.fontWeight')}
                    </FormGroup>
                    <FormGroup controlId="cancelledPriceStyle.color">
                      <Col sm={6} componentClass={ControlLabel}>打ち消された価格の文字色</Col>
                      <Col sm={6}>
                        <FormControl
                          name="cancelledPriceStyle.color"
                          type="color"
                          placeholder="#FFFFFF"
                          value={this.state.cancelledPriceStyle.color}
                          onChange={(e) => this.setState({ cancelledPriceStyle: {...this.state.cancelledPriceStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                          />
                      </Col>
                      {messages('cancelledPriceStyle.color')}
                    </FormGroup>
                    <FormGroup controlId="cancelledPriceStyle.fontSize">
                      <Col sm={6} componentClass={ControlLabel}>打ち消された価格の文字のサイズ (px)</Col>
                      <Col sm={6}>
                        <FormControl
                          name="cancelledPriceStyle.fontSize"
                          type="number"
                          placeholder="12"
                          value={this.state.cancelledPriceStyle.fontSize}
                          onChange={(e) => this.setState({ cancelledPriceStyle: {...this.state.cancelledPriceStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                          />
                      </Col>
                      {messages('cancelledPriceStyle.fontSize')}
                    </FormGroup>
                    <FormGroup controlId="cancelledPriceStyle.fontWeight">
                      <Col sm={6} componentClass={ControlLabel}>打ち消された価格の文字の太さ</Col>
                      <Col sm={6}>
                        <FormControl
                          name="cancelledPriceStyle.fontWeight"
                          type="number"
                          placeholder="12"
                          value={this.state.cancelledPriceStyle.fontWeight}
                          onChange={(e) => this.setState({ cancelledPriceStyle: {...this.state.cancelledPriceStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                          />
                      </Col>
                      {messages('cancelledPriceStyle.fontWeight')}
                    </FormGroup>
                  </div>}
                  <FormGroup controlId="subtitleStyle.color">
                    <Col sm={6} componentClass={ControlLabel}>サブタイトルの文字色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="subtitleStyle.color"
                        type="color"
                        placeholder="#ff0000"
                        value={this.state.subtitleStyle.color}
                        onChange={(e) => this.setState({ subtitleStyle: {...this.state.subtitleStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                        />
                      {messages('subtitleStyle.color')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="subtitleStyle.fontSize">
                    <Col sm={6} componentClass={ControlLabel}>サブタイトルの文字サイズ (px)</Col>
                    <Col sm={6}>
                      <FormControl
                        name="subtitleStyle.fontSize"
                        type="number"
                        placeholder="14"
                        value={this.state.subtitleStyle.fontSize}
                        onChange={(e) => this.setState({ subtitleStyle: {...this.state.subtitleStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('subtitleStyle.fontSize')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="subtitleStyle.fontWeight">
                    <Col sm={6} componentClass={ControlLabel}>サブタイトルの文字の太さ</Col>
                    <Col sm={6}>
                      <FormControl
                        name="subtitleStyle.fontWeight"
                        type="number"
                        placeholder="14"
                        value={this.state.subtitleStyle.fontWeight}
                        onChange={(e) => this.setState({ subtitleStyle: {...this.state.subtitleStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('subtitleStyle.fontWeight')}
                    </Col>
                  </FormGroup>
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="aboutItemStyle.color">
                      <Col sm={6} componentClass={ControlLabel}>この商品についての文字色</Col>
                      <Col sm={6}>
                        <FormControl
                          name="aboutItemStyle.color"
                          type="color"
                          placeholder="#333333"
                          value={this.state.aboutItemStyle.color}
                          onChange={(e) => this.setState({ aboutItemStyle: {...this.state.aboutItemStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                          />
                        {messages('aboutItemStyle.color')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="aboutItemStyle.fontSize">
                      <Col sm={6} componentClass={ControlLabel}>この商品についての文字サイズ (px)</Col>
                      <Col sm={6}>
                        <FormControl
                          name="aboutItemStyle.fontSize"
                          type="number"
                          placeholder="14"
                          value={this.state.aboutItemStyle.fontSize}
                          onChange={(e) => this.setState({ aboutItemStyle: {...this.state.aboutItemStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                          />
                        {messages('aboutItemStyle.fontSize')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="aboutItemStyle.fontWeight">
                      <Col sm={6} componentClass={ControlLabel}>この商品についての文字の太さ</Col>
                      <Col sm={6}>
                        <FormControl
                          name="aboutItemStyle.fontWeight"
                          type="number"
                          placeholder="14"
                          value={this.state.aboutItemStyle.fontWeight}
                          onChange={(e) => this.setState({ aboutItemStyle: {...this.state.aboutItemStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                          />
                        {messages('aboutItemStyle.fontWeight')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="aboutItemText">
                      <Col sm={6} componentClass={ControlLabel}>この商品についてのテキスト</Col>
                      <Col sm={6}>
                        <FormControl
                          name="aboutItemText"
                          type="text"
                          placeholder="この商品について"
                          value={this.state.aboutItemText}
                          onChange={(e) => this.setState({ aboutItemText: this.normalizeText(e.target.value) })}
                          />
                        {messages('aboutItemText')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="buyButtonStyle.backgroundColor">
                      <Col sm={6} componentClass={ControlLabel}>購入ボタンの背景色</Col>
                      <Col sm={6}>
                        <FormControl
                          name="buyButtonStyle.backgroundColor"
                          type="text"
                          placeholder="#666666"
                          value={this.state.buyButtonStyle.backgroundColor}
                          onChange={(e) => this.setState({ buyButtonStyle: {...this.state.buyButtonStyle, ...{ backgroundColor: this.normalizeText(e.target.value) }} })}
                          />
                        {messages('buyButtonStyle.backgroundColor')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="buyButtonStyle.borderRadius">
                      <Col sm={6} componentClass={ControlLabel}>購入ボタンの角の丸み</Col>
                      <Col sm={6}>
                        <FormControl
                          name="buyButtonStyle.borderRadius"
                          type="number"
                          placeholder="16"
                          value={this.state.buyButtonStyle.borderRadius}
                          onChange={(e) => this.setState({ buyButtonStyle: {...this.state.buyButtonStyle, ...{ borderRadius: this.normalizeNumber(e.target.value) }} })}
                          />
                        {messages('buyButtonStyle.borderRadius')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="buyButtonStyle.color">
                      <Col sm={6} componentClass={ControlLabel}>購入ボタンの文字色</Col>
                      <Col sm={6}>
                        <FormControl
                          name="buyButtonStyle.color"
                          type="color"
                          placeholder="#ffffff"
                          value={this.state.buyButtonStyle.color}
                          onChange={(e) => this.setState({ buyButtonStyle: {...this.state.buyButtonStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                          />
                        {messages('buyButtonStyle.color')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="buyButtonStyle.fontSize">
                      <Col sm={6} componentClass={ControlLabel}>購入ボタンの文字サイズ (px)</Col>
                      <Col sm={6}>
                        <FormControl
                          name="buyButtonStyle.fontSize"
                          type="number"
                          placeholder="12"
                          value={this.state.buyButtonStyle.fontSize}
                          onChange={(e) => this.setState({ buyButtonStyle: {...this.state.buyButtonStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                          />
                        {messages('buyButtonStyle.fontSize')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="buyButtonStyle.fontWeight">
                      <Col sm={6} componentClass={ControlLabel}>購入ボタンの文字の太さ</Col>
                      <Col sm={6}>
                        <FormControl
                          name="buyButtonStyle.fontWeight"
                          type="number"
                          placeholder="700"
                          value={this.state.buyButtonStyle.fontWeight}
                          onChange={(e) => this.setState({ buyButtonStyle: {...this.state.buyButtonStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                          />
                        {messages('buyButtonStyle.fontWeight')}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.itemListStyle === 'list' && (
                    <FormGroup controlId="buyButtonText">
                      <Col sm={6} componentClass={ControlLabel}>購入ボタンのテキスト</Col>
                      <Col sm={6}>
                        <FormControl
                          name="buyButtonText"
                          type="text"
                          placeholder="詳細を見る"
                          value={this.state.buyButtonText}
                          onChange={(e) => this.setState({ buyButtonText: this.normalizeText(e.target.value) })}
                          />
                        {messages('buyButtonText')}
                      </Col>
                    </FormGroup>
                  )}
                  <FormGroup controlId="messageStyle">
                    <Col componentClass={ControlLabel} sm={6}>コメントのフォント</Col>
                    <Col sm={6}>
                      <Radio name="messageStyle" value="whiteColorMessage" onChange={() => this.setState({ messageStyle: 'whiteColorMessage' })} checked={this.state.messageStyle === 'whiteColorMessage'}>
                        <span className="whiteColorMessage">
                          あいうえおaiueo
                        </span>
                      </Radio>
                      <Radio name="messageStyle" value="darkColorMessage" onChange={() => this.setState({ messageStyle: 'darkColorMessage' })} checked={this.state.messageStyle === 'darkColorMessage'}>
                        <span className="darkColorMessage">
                          あいうえおaiueo
                        </span>
                      </Radio>
                      <Radio name="messageStyle" value="withBackgroundMessage" onChange={() => this.setState({ messageStyle: 'withBackgroundMessage' })} checked={this.state.messageStyle === 'withBackgroundMessage'}>
                        <span className="withBackgroundMessage">
                          あいうえおaiueo
                        </span>
                      </Radio>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="liveListGridCellDateStyle.color">
                    <Col sm={6} componentClass={ControlLabel}>ライブ一覧の日付の文字色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="liveListGridCellDateStyle.color"
                        type="color"
                        placeholder="#999999"
                        value={this.state.liveListGridCellDateStyle.color}
                        onChange={(e) => this.setState({ liveListGridCellDateStyle: {...this.state.liveListGridCellDateStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                        />
                      {messages('liveListGridCellDateStyle.color')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="liveListGridCellDateStyle.fontSize">
                    <Col sm={6} componentClass={ControlLabel}>ライブ一覧の日付の文字サイズ (px)</Col>
                    <Col sm={6}>
                      <FormControl
                        name="liveListGridCellDateStyle.fontSize"
                        type="number"
                        placeholder="11"
                        value={this.state.liveListGridCellDateStyle.fontSize}
                        onChange={(e) => this.setState({ liveListGridCellDateStyle: {...this.state.liveListGridCellDateStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('liveListGridCellDateStyle.fontSize')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="liveListGridCellDateStyle.fontWeight">
                    <Col sm={6} componentClass={ControlLabel}>ライブ一覧の日付の文字の太さ</Col>
                    <Col sm={6}>
                      <FormControl
                        name="liveListGridCellDateStyle.fontWeight"
                        type="number"
                        placeholder="12"
                        value={this.state.liveListGridCellDateStyle.fontWeight}
                        onChange={(e) => this.setState({ liveListGridCellDateStyle: {...this.state.liveListGridCellDateStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('liveListGridCellDateStyle.fontWeight')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="liveListGridCellNameStyle.color">
                    <Col sm={6} componentClass={ControlLabel}>ライブ一覧の名前の文字色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="liveListGridCellNameStyle.color"
                        type="color"
                        placeholder="#000000"
                        value={this.state.liveListGridCellNameStyle.color}
                        onChange={(e) => this.setState({ liveListGridCellNameStyle: {...this.state.liveListGridCellNameStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                        />
                      {messages('liveListGridCellNameStyle.color')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="liveListGridCellNameStyle.fontSize">
                    <Col sm={6} componentClass={ControlLabel}>ライブ一覧の名前の文字サイズ (px)</Col>
                    <Col sm={6}>
                      <FormControl
                        name="liveListGridCellNameStyle.fontSize"
                        type="number"
                        placeholder="12"
                        value={this.state.liveListGridCellNameStyle.fontSize}
                        onChange={(e) => this.setState({ liveListGridCellNameStyle: {...this.state.liveListGridCellNameStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('liveListGridCellNameStyle.fontSize')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="liveListGridCellNameStyle.fontWeight">
                    <Col sm={6} componentClass={ControlLabel}>ライブ一覧の名前の文字の太さ</Col>
                    <Col sm={6}>
                      <FormControl
                        name="liveListGridCellNameStyle.fontWeight"
                        type="number"
                        placeholder="12"
                        value={this.state.liveListGridCellNameStyle.fontWeight}
                        onChange={(e) => this.setState({ liveListGridCellNameStyle: {...this.state.liveListGridCellNameStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                        />
                      {messages('liveListGridCellNameStyle.fontWeight')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="countDownFont">
                    <Col sm={6} componentClass={ControlLabel}>カウントダウンのフォント</Col>
                    <Col sm={6}>
                      <FormControl
                        name="countDownFont"
                        type="text"
                        placeholder="Noto Sans JP, Arial, Helvetica, sans-serif"
                        value={this.state.countDownFont}
                        onChange={(e) => this.setState({ countDownFont: this.normalizeText(e.target.value) })}
                        className="theme_textInput-wide"
                        />
                      {messages('countDownFont')}
                    </Col>
                  </FormGroup>
                   <FormGroup controlId="countDownColor">
                    <Col sm={6} componentClass={ControlLabel}>カウントダウンの文字色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="countDownColor"
                        type="text"
                        placeholder="#FFFFFF"
                        value={this.state.countDownColor}
                        onChange={(e) => this.setState({ countDownColor: this.normalizeText(e.target.value) })}
                        />
                      {messages('countDownColor')}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="saleIconStyle.text">
                    <Col sm={6} componentClass={ControlLabel}>セールのアイコンのテキスト</Col>
                    <Col sm={6}>
                      <FormControl
                        name="saleIconStyle.text"
                        type="text"
                        placeholder="SALE"
                        value={this.state.saleIconStyle.text}
                        onChange={(e) => this.setState({ saleIconStyle: {...this.state.saleIconStyle, ...{ text: this.normalizeText(e.target.value) }} })}
                        />
                    </Col>
                    {messages('saleIconStyle.text')}
                    </FormGroup>
                  <FormGroup controlId="saleIconStyle.color">
                    <Col sm={6} componentClass={ControlLabel}>セールのアイコンの文字色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="saleIconStyle.color"
                        type="color"
                        placeholder="#FF0000"
                        value={this.state.saleIconStyle.color}
                        onChange={(e) => this.setState({ saleIconStyle: {...this.state.saleIconStyle, ...{ color: this.normalizeText(e.target.value) }} })}
                        />
                    </Col>
                    {messages('saleIconStyle.color')}
                  </FormGroup>
                  <FormGroup controlId="saleIconStyle.backgroundColor">
                    <Col sm={6} componentClass={ControlLabel}>セールのアイコンの背景色</Col>
                    <Col sm={6}>
                      <FormControl
                        name="saleIconStyle.backgroundColor"
                        type="color"
                        placeholder="#FFFFFF"
                        value={this.state.saleIconStyle.backgroundColor}
                        onChange={(e) => this.setState({ saleIconStyle: {...this.state.saleIconStyle, ...{ backgroundColor: this.normalizeText(e.target.value) }} })}
                        />
                    </Col>
                    {messages('saleIconStyle.backgroundColor')}
                  </FormGroup>
                  <FormGroup controlId="saleIconStyle.fontSize">
                    <Col sm={6} componentClass={ControlLabel}>セールのアイコンの文字サイズ (px)</Col>
                    <Col sm={6}>
                      <FormControl
                        name="saleIconStyle.fontSize"
                        type="number"
                        placeholder="8"
                        value={this.state.saleIconStyle.fontSize}
                        onChange={(e) => this.setState({ saleIconStyle: {...this.state.saleIconStyle, ...{ fontSize: this.normalizeNumber(e.target.value) }} })}
                        />
                    </Col>
                    {messages('saleIconStyle.fontSize')}
                  </FormGroup>
                  <FormGroup controlId="saleIconStyle.fontWeight">
                    <Col sm={6} componentClass={ControlLabel}>セールのアイコンの文字の太さ</Col>
                    <Col sm={6}>
                      <FormControl
                        name="saleIconStyle.fontWeight"
                        type="number"
                        placeholder="400"
                        value={this.state.saleIconStyle.fontWeight}
                        onChange={(e) => this.setState({ saleIconStyle: {...this.state.saleIconStyle, ...{ fontWeight: this.normalizeNumber(e.target.value) }} })}
                        />
                    </Col>
                    {messages('saleIconStyle.fontWeight')}
                  </FormGroup>
                  <FormGroup controlId="ngwords">
                    <Col sm={6} componentClass={ControlLabel}>コメントのNGワード</Col>
                    <Col sm={6}>
                      <FormControl
                        name="ngwords"
                        type="text"
                        placeholder="カンマ区切りスペースなし"
                        value={this.state.ngwords}
                        onChange={(e) => this.setState({ ngwords: this.normalizeText(e.target.value)})}
                        className="theme_textInput-wide"
                        />
                      <div className="formcaption">
                        <p>カンマ区切りスペースなしでお願いします。</p>
                      </div>
                    </Col>
                    {messages('ngwords')}
                  </FormGroup>
                </form>
              </div>
              <div className="fixFooter">
                <div className="fixFooterInner">
                  <div className="fixFooterBtnArea">
                    {!!errors.count &&
                      <span className="error mr10">{errors.count}件のエラーがあります</span>
                    }
                    {!errors.count && this.state.succeeded &&
                      <span className="success mr10">
                        <img className="mr10" src={doneIcon} alt="succes" />
                        {this.props.edit ? 'テーマが更新されました' : 'テーマが登録されました'}
                      </span>
                    }
                    {this.state.failedToCreate &&
                      <span className="error mr10">このIDは既に使用されているため登録できません</span>
                    }
                    {this.state.failedToUpdate &&
                      <span className="error mr10">このIDに対する更新権限がないため更新できません</span>
                    }
                    {this.state.failedToDelete &&
                      <span className="error mr10">このIDに対する削除権限がないため削除できません</span>
                    }
                    {this.props.edit && (
                      <Button className="strongDeleteButton" onClick={() => this.deleteTheme()}>
                        削除
                      </Button>)}
                    <Button className="marginRightL strongBtn" disabled={!!errors.count || this.state.processing} onClick={() => this.setTheme()}>
                      {this.props.edit ? '更新する' : '登録する'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
